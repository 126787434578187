import React from 'react'
import styles from './CheckoutEditAddressOverlay.module.scss'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  SvgIcon,
  Typography,
  TextField,
  Modal,
  Divider,
  useTheme,
  IconButton,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { Confirm } from '../alert-and-confirm/Confirm'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { checkSplittAddress, splittAddress } from '@obeta/utils/lib/splitt-address'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { DarkButton } from '../custom-button/CustomButton'

interface Props {
  createUserAddress: (address: AddressV2, customName: string) => void
  showAddAddressOverlay: boolean
  setShowAddAddressOverlay: (i: boolean) => void
}

interface ModalAddressObject {
  name1: string
  name2: string
  zipCode: string
  address: string
  city: string
  customName: string
}

export const CheckoutAddAddressOverlay: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ModalAddressObject>({ mode: 'onChange' })
  const theme = useTheme()
  const { desktop, mobile } = useBreakpoints()

  const { createUserAddress, showAddAddressOverlay, setShowAddAddressOverlay } = props
  const [showUnsavedChangesConfirm, setShowUnsavedChangesConfirm] = React.useState(false)

  const onSubmit = (data: ModalAddressObject) => {
    const adressSplitt = splittAddress(data.address)

    createUserAddress(
      {
        name1: data.name1,
        name2: data.name2 ?? '', // Note: Whilst not a required user input field, it's a non-optional AddressV2 property
        street: adressSplitt?.[1] ?? '',
        houseNumber: adressSplitt?.[2] ?? '',
        zipCode: data.zipCode,
        city: data.city,
      },
      data.customName ?? ''
    )
    // Added address should now be pre-selected within CheckoutAddressAndStoreSearchOverlay ?!

    // close overlay
    showAddAddressOverlay && setShowAddAddressOverlay(false)
  }

  const handleConfirmCloseDespiteUnsavedChanges = () => {
    // close confirm
    setShowUnsavedChangesConfirm(false)
    // close overlay
    showAddAddressOverlay && setShowAddAddressOverlay && setShowAddAddressOverlay(false)
  }
  const handleCancelCloseBecauseOfUnsavedChanges = () => {
    // close confirm
    setShowUnsavedChangesConfirm(false)
    // continue showing overlay
  }

  // triggered by back button, escapeKeyDown or backdropClick
  const handleClose = () => {
    setShowUnsavedChangesConfirm(true)
  }

  // error messages to be displayed as helper texts
  let name1ErrorMessage,
    addressErrorMessage,
    cityErrorMessage = ''

  if (errors.name1 && errors.name1.type === 'required') {
    name1ErrorMessage = t('ADDRESSES.ERROR.COMPANY_REQUIRED')
  } else if (errors.name1 && errors.name1.type === 'maxLength') {
    name1ErrorMessage = t('ADDRESSES.ERROR.COMPANY_MAX_LENGTH')
  }

  if (errors.address && errors.address.type === 'required') {
    addressErrorMessage = t('ADDRESSES.ERROR.ADDRESS_REQUIRED')
  } else if (errors.address && errors.address.type === 'maxLength') {
    addressErrorMessage = t('ADDRESSES.ERROR.STREET_NUMBER_MAX_LENGTH')
  } else {
    addressErrorMessage = t('ADDRESSES.ERROR.ADDRESS_INVALID')
  }

  if (errors.city && errors.city.type === 'required') {
    cityErrorMessage = t('ADDRESSES.ERROR.CITY_REQUIRED')
  } else if (errors.city && errors.city.type === 'maxLength') {
    cityErrorMessage = t('ADDRESSES.ERROR.CITY_INVALID')
  }

  //Note: As this is always a new empty form, value attribute is not needed for the form input elements
  return (
    <>
      <Modal
        open={showAddAddressOverlay || false}
        onClose={handleClose}
        aria-labelledby="modal-modal-checkout-add-address"
        aria-describedby="modal-modal-description"
      >
        <Box
          flexDirection={'row'}
          maxWidth={'45.625rem'}
          padding={desktop ? '2.5rem' : '1.5rem'}
          className={styles.checkoutEditAddressOverlay}
        >
          <Box display={'flex'}>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={handleClose}
              className={styles.buttonAddressLocationCard}
            >
              <SvgIcon
                component={ChevronLeftIcon}
                fontSize={'large'}
                htmlColor={theme.palette.grayVariant.dark}
              />
            </IconButton>
            <Typography variant={'h4'}>
              {mobile ? t('ADDRESSES.ADDRESS_MANAGEMENT') : t('ADDRESSES.YOUR_ADDRESS_MANAGEMENT')}
            </Typography>
          </Box>
          <Box className={styles.typographyWrapper}>
            <Typography variant={'bodyBold'}>
              {t('SHOPPING_CART.CHECKOUT.ADD_DELIVERY_ADDRESS')}
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid className={styles.typographyWrapper} size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.EDIT_ADDRESS_WONT_SAVE')}
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="customName"
                    name="customName"
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.customName ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.customName.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.CUSTOM_NAME_MAX_LENGTH')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="customName"
                control={control}
                rules={{
                  maxLength: 23,
                }}
              />
            </Grid>
            <Grid className={styles.typographyWrapper} size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.COMPANY_NAME')}*
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    error={!!errors.name1}
                    className={styles.text_input}
                    variant="outlined"
                    id="name1"
                    name="name1"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.name1 ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {name1ErrorMessage}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="name1"
                defaultValue={''}
                control={control}
                rules={{
                  required: true,
                  maxLength: 35,
                }}
              />
            </Grid>
            <Grid className={styles.typographyWrapper} size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.CUSTOMER_NAME')}
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="name2"
                    name="name2"
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.name2 ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.name2.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.NAME_MAX_LENGTH')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="name2"
                control={control}
                rules={{
                  maxLength: 35,
                }}
              />
            </Grid>

            <Grid className={styles.typographyWrapper} size={{ xs: 12 }}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.ADDRESS')}*
              </Typography>
              <Controller
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="address"
                    name="address"
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.address ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {addressErrorMessage}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="address"
                control={control}
                rules={{
                  required: true,
                  maxLength: 60,
                  validate: checkSplittAddress,
                }}
              />
            </Grid>

            <div className={styles.singleLine}>
              <div className={styles.typographyWrapper}>
                <Typography variant={'body'}>
                  {t('SHOPPING_CART.CHECKOUT.ADDRESS.ZIP_CODE')}*
                </Typography>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      error={!!errors.zipCode}
                      className={styles.text_input}
                      variant="outlined"
                      id="zipCode"
                      name="zipCode"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      helperText={
                        errors.zipCode ? (
                          <Box display={'flex'}>
                            <SvgIcon
                              className={styles.helperTextIcon}
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.zipCode.type === 'required'
                                ? t('ADDRESSES.ERROR.ZIP_CODE_REQUIRED')
                                : t('ADDRESSES.ERROR.ZIP_CODE_INVALID')}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                    />
                  )}
                  name="zipCode"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^\d{5}$/i,
                      message: t('ADDRESSES.ERROR.ZIP_CODE_INVALID'),
                    },
                  }}
                  defaultValue={''}
                />
              </div>
              <div className={styles.typographyWrapper}>
                <Typography variant={'body'}>
                  {t('SHOPPING_CART.CHECKOUT.ADDRESS.CITY')}*
                </Typography>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      error={!!errors.city}
                      className={styles.text_input}
                      variant="outlined"
                      id="city"
                      name="city"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      helperText={
                        errors.city ? (
                          <Box display={'flex'}>
                            <SvgIcon
                              className={styles.helperTextIcon}
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {cityErrorMessage}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                    />
                  )}
                  name="city"
                  defaultValue={''}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                  }}
                />
              </div>
            </div>
            <Divider className={styles.divider} />
            <Box
              display={'flex'}
              justifyContent={!desktop ? 'center' : 'flex-end'}
              alignItems={'stretch'}
              className={styles.buttonWrapper}
            >
              <DarkButton type="submit" disabled={!isValid} fullWidth>
                {t('SHOPPING_CART.BUTTON_ACTIONS.SET_DELIVERY_ADDRESS')}
              </DarkButton>
            </Box>
          </form>
        </Box>
      </Modal>
      <Confirm
        heading={t('ADDRESSES.UNSAVED_ADDRESS')}
        body={t('ADDRESSES.CONFIRM.CONTINUE_DESPITE_UNSAVED_ADDRESS')}
        handleConfirm={handleConfirmCloseDespiteUnsavedChanges}
        handleCancel={handleCancelCloseBecauseOfUnsavedChanges}
        openConfirmDialog={showUnsavedChangesConfirm}
      />
    </>
  )
}
