import { getArticleSeoData } from './fetchArticleDataServerSide'
import { ShopRoutes } from '@obeta/utils/lib/variables'

// Since the App is currently generated on the client due to client-dependent logic
// (such as setState, rxdb, etc.), we retrieve the necessary SEO meta tags here using an SSR approach.
export const getInitialPropsAppCallback = async ({ router }) => {
  const { asPath } = router
  if (asPath.includes(ShopRoutes.ArticleDetailPage)) {
    const articleId = asPath.split('/').pop()?.split('?')[0]

    if (!articleId) return {}

    return await getArticleSeoData(articleId)
  }

  return {}
}
