/**
 * Data related to shipping options.
 */
import { PaymentMethod } from '@obeta/schema'
import { AddressV2 } from './AddressV2'

export interface ShippingOptions {
  shippingType: ShippingType
  isEnabled: boolean
  isDefault: boolean
  deliveryAddress: AddressV2
  storeId: string
  disabledReason?: DisabledReason
  shippingCosts: ShippingCosts
  shippingDates: ShippingDate[]
  paymentMethods: PaymentMethod[]
}

export enum ShippingType {
  // Deliveries
  DefaultParcel = 'DefaultParcel', // Standardpaketdienst
  FastParcel = 'FastParcel', // Eilpaketdienst
  Cargo = 'Cargo', // Per Anlieferung / LKW, Rad

  // Pickups
  DefaultPickup = 'DefaultPickup', // Selbstabholung
  ExpressPickup = 'ExpressPickup', // Selbstabholung (Click-And-Collect) - Nicht von SAP unterstützt
  PickupRenzbox = 'PickupRenzbox', // Selbstabholung Renzbox
}

export enum DisabledReason {
  NotForCashCustomers = 'NotForCashCustomers',
  NotAllForParcel = 'NotAllForParcel',
  NotAllAvailableInStore = 'NotAllAvailableInStore',
}

export interface ShippingCosts {
  total: number
  currency: string
  freeDeliveryThreshold?: number
}

export interface ShippingDate {
  // RFC3339 date string YYYY-MM-DD
  date: string
  time?: string
}

export enum ShippingGroup {
  Pickup = 'Pickup',
  Delivery = 'Delivery',
}
