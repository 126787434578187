import { ArticleRing } from '@obeta/models/lib/models/Article/ArticleListItem'
import {
  MoveCartItemsOfferIdUpdateEnum,
  ShoppingCart,
  ShoppingCartItem,
  ShoppingCartUpdateResult,
  ShoppingCartV2,
  VerifyOfferInShoppingCartResult,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import {
  ShoppingCartUpdateResult as ShoppingCartUpdateResultFromSchema,
  AddOrderItemsToCartInput,
  IdsFormFieldsResult,
  MultiSelectionSearch,
  OfferAmount,
  OciCartFormFieldsResponse,
  ShoppingCartUpdateMetaDataInput,
  AddOrderItemsInArrearsToCartInput,
} from '@obeta/schema'
import { CartFromManualUpdate } from '@obeta/models/lib/schema-models/cart-manual-update'
export interface CartsAction {
  type: CartsActionTypes
}

export enum CartsActionTypes {
  AddArticleToCart = '[Carts] Add Article To Cart',
  AddCartTemplatesToCartGraphQL = '[Carts] Add all articles of a cart template to cart via cartTemplateId',
  AddCartTemplatesToCartGraphQLResult = '[Carts] Add all articles of a cart template to cart via cartTemplateId Result',
  AddCartTemplateItemsToCartGraphQL = '[Carts] Add a single, some, or all cart template items to cart via cartTemplateId',
  AddCartTemplateItemsToCartGraphQLResult = '[Carts] Add a single, some, or all cart template items to cart  via cartTemplateId Result',
  AddOrderItemsToCartGraphQL = '[Carts] Add order items to cart via GraphQL',
  AddOrderItemsToCartGraphQLResult = '[Carts] Add order items to cart via GraphQL Result',
  AddOrderItemsInArrearsToCartGraphQL = '[Carts] Add order items in arrears to cart via GraphQL',
  AddOrderItemsInArrearsToCartGraphQLResult = '[Carts] Add order items in arrears to cart via GraphQL Result',
  AddOrReplaceOfferInCartGraphQL = '[Carts] Add or replace offer (and place offer items) in cart via GraphQL',
  AddOrReplaceOfferInCartGraphQLResult = '[Carts] Add or replace offer (and place offer items) in cart via GraphQL Result',
  UpdateCartArticleAmountAction = '[Carts] Update Amount of Article in Cart',
  SendCart = '[Carts] Send Cart',
  SendCartResult = '[Carts] Send Cart Result',
  MoveCartItemsGraphQL = '[Carts] Move Cart Item(s) into a new cart',
  MoveCartItemsGraphQLResult = '[Carts] Move Cart Item(s) into a new cart Result',
  NotifyCartGraphQL = '[Carts] Dispatch a Cart Notification',
  NotifyCartGraphQLResult = '[Carts] Dispatch a Cart Notification Result',
  SubmitCartGraphQL = '[Carts] Dispatch a Cart Submission',
  SubmitCartGraphQLResult = '[Carts] Dispatch a Cart Submission Result',
  RemoveArticleFromCartAction = '[Carts] Remove Article from Cart',
  DeleteCartItemsGraphQL = '[Carts] Remove Cart Item(s) from Cart via GraphQL',
  DeleteCartItemsGraphQLResult = '[Carts] Remove Cart Item(s) from Cart via GraphQL Result',
  EmptyShoppingCartGraphQL = '[Carts] Empty Cart via GraphQL',
  EmptyShoppingCartGraphQLResult = '[Carts] Empty Cart via GraphQL Result',
  GetShoppingCartPricesGraphQL = '[Carts] Get Prices via GraphQL',
  GetShoppingCartPricesGraphQLResult = '[Carts] Get Prices via GraphQL Result',
  ResetShoppingCartGraphQL = '[Carts] Reset Cart via GraphQL',
  ResetShoppingCartGraphQLResult = '[Carts] Reset Cart via GraphQL Result',
  AddMarkForNotification = '[Carts] Add Mark For Notification',
  RemoveMarkForNotification = '[Carts] Remove Mark For Notification',
  UpdateCartGraphQL = '[Carts] Update a Cart via GraphQL',
  UpdateCartGraphQLResult = '[Carts] Update a Cart via GraphQL Result',
  ChangeProductAmountGraphQL = '[Carts] Update Amount of Article in Cart for the New Shop',
  ChangeProductAmountGraphQLResult = '[Carts] Update Amount of Article in Cart for the New Shop Result',
  AddProductToCartGraphQL = '[Carts] Add Product To Cart GraphQL',
  AddProductToCartGraphQLResult = '[Carts] Add Product To Cart GraphQL Result',
  VerifyCartGraphQL = '[Carts] Verify a Cart via GraphQL',
  VerifyCartGraphQLResult = '[Carts] Verify a Cart via GraphQL Result',
  UpdateCustomProductsGraphQL = '[Carts] Get custom products information to show in cart',
  UpdateCustomProductsGraphQLResult = '[Carts] Get custom products information to show in cart Result',
  GetVoltimumPointsGraphQL = '[Carts] Get voltimum points for products in cart',
  GetVoltimumPointsGraphQLResult = '[Carts] Get voltimum points for products in cart Result',
  GetIdsFormFieldsGraphQL = '[Carts] Get Ids form-fields',
  GetIdsFormFieldsGraphQLResult = '[Carts] Get Ids form-fields Result',
  GetOciFormFieldsGraphQL = '[Carts] Get Oci form-fields',
  GetOciFormFieldsGraphQLResult = '[Carts] Get Oci form-fields Result',
  VerifyOfferInCartInOfferGraphQL = '[Carts] Verify a Cart and the selected Offer',
  VerifyOfferInCartGraphQLResult = '[Carts] Verify a Cart and the selected Offer Result',
  AddToCartFromIdsXml = '[Carts] Add To Cart From Ids Xml',
  AddToCartFromIdsXmlResult = '[Carts] Add To Cart From Ids Xml Result',
  ManuallyUpdateCarts = '[Carts] Manually update carts positions ',
  ManuallyUpdateCartsResult = '[Carts] Manually update carts positions  Result',
}

export interface ProductItem {
  sapId: string
  amount: number
  discount?: { offerId: string; offerItemPosition: string }
  title?: string
  success?: boolean
  errorCode?: string
  errorMessage?: string
}

export enum AddCartContextEnum {
  CartImport,
  CartTemplate,
  Generic,
  SearchResult,
}

interface AddProductToCartGraphQLPayload {
  cartId: string
  cartTitle?: string
  templateId?: string //temporary fix until we have endpoints that can handle moving whole template
  items: ProductItem[]
  context?: AddCartContextEnum
}

export interface AddProductToCartGraphQLAction {
  readonly type: CartsActionTypes.AddProductToCartGraphQL
  payload: AddProductToCartGraphQLPayload
}

export interface AddProductToCartGraphQLActionResult {
  readonly type: CartsActionTypes.AddProductToCartGraphQLResult
  payload: Omit<ShoppingCartUpdateResult, 'itemResults'> | null
}

export const addProductToCartGraphQL = (
  payload: AddProductToCartGraphQLPayload
): AddProductToCartGraphQLAction => {
  return { type: CartsActionTypes.AddProductToCartGraphQL, payload }
}

export const addProductToCartGraphQLResult = (
  payload: Omit<ShoppingCartUpdateResult, 'itemResults'> | null
): AddProductToCartGraphQLActionResult => {
  return { type: CartsActionTypes.AddProductToCartGraphQLResult, payload }
}

interface AddCartTemplatesToCartGraphQLPayload {
  cartId: string
  cartTemplateIds: string[]
  cartName: string
  cartTemplateName: string
}

export interface AddCartTemplatesToCartGraphQLAction {
  readonly type: CartsActionTypes.AddCartTemplatesToCartGraphQL
  payload: AddCartTemplatesToCartGraphQLPayload
}

export interface AddCartTemplatesToCartGraphQLActionResult {
  readonly type: CartsActionTypes.AddCartTemplatesToCartGraphQLResult
  payload: Omit<ShoppingCartUpdateResult, 'itemResults'> | null
}

export const addCartTemplatesToCartGraphQL = (
  payload: AddCartTemplatesToCartGraphQLPayload
): AddCartTemplatesToCartGraphQLAction => {
  return {
    type: CartsActionTypes.AddCartTemplatesToCartGraphQL,
    payload,
  }
}

export const addCartTemplatesToCartGraphQLResult = (
  payload: Omit<ShoppingCartUpdateResult, 'itemResults'> | null
): AddCartTemplatesToCartGraphQLActionResult => {
  return { type: CartsActionTypes.AddCartTemplatesToCartGraphQLResult, payload }
}

export interface AddCartTemplateItemsToCartGraphQLAction {
  readonly type: CartsActionTypes.AddCartTemplateItemsToCartGraphQL
  cartId: string
  cartName: string
  cartTemplateId: number
  cartTemplateName: string
  include: Array<string>
  exclude: Array<string>
  search: MultiSelectionSearch | null
  selectedItemCount: number
}

export interface AddCartTemplateItemsToCartGraphQLActionResult {
  readonly type: CartsActionTypes.AddCartTemplateItemsToCartGraphQLResult
  success: boolean
  successItemCount: number
  errorCode: string
  errorMessage: string
  error?: Error
}

export const addCartTemplateItemsToCartGraphQL = (
  cartId: string,
  cartName: string,
  cartTemplateId: number,
  cartTemplateName: string,
  include: Array<string>,
  exclude: Array<string>,
  search: MultiSelectionSearch | null,
  selectedItemCount: number
): AddCartTemplateItemsToCartGraphQLAction => {
  return {
    type: CartsActionTypes.AddCartTemplateItemsToCartGraphQL,
    cartId,
    cartName,
    cartTemplateId,
    cartTemplateName,
    include,
    exclude,
    search,
    selectedItemCount,
  }
}
export const addCartTemplateItemsToCartGraphQLResult = (
  success: boolean,
  successItemCount: number,
  errorCode: string,
  errorMessage: string
): AddCartTemplateItemsToCartGraphQLActionResult => {
  return {
    type: CartsActionTypes.AddCartTemplateItemsToCartGraphQLResult,
    success,
    successItemCount,
    errorCode,
    errorMessage,
  }
}

interface addToCartParams {
  articleId: string
  articleName: string
  cart: ShoppingCartV2
  ring: ArticleRing
  amount: number
}

export interface AddArticleToCartAction extends CartsAction, addToCartParams {
  readonly type: CartsActionTypes.AddArticleToCart
}

export const addArticleToCart = (payload: addToCartParams): AddArticleToCartAction => ({
  type: CartsActionTypes.AddArticleToCart,
  ...payload,
})

export interface AddOrderItemsToCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.AddOrderItemsToCartGraphQL
  input: AddOrderItemsToCartInput
  selectedItemCount: number
}

export const addOrderItemsToCartGraphQL = (
  input: AddOrderItemsToCartInput,
  selectedItemCount: number
): AddOrderItemsToCartGraphQLAction => ({
  type: CartsActionTypes.AddOrderItemsToCartGraphQL,
  input,
  selectedItemCount,
})

export interface AddOrderItemsToCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.AddOrderItemsToCartGraphQLResult
  success: boolean
  errorCode: string
  errorMessage: string
  orderId: string
  error?: Error
}

export const AddOrderItemsToCartGraphQLResult = (
  success: boolean,
  errorCode: string,
  errorMessage: string,
  orderId: string,
  error?: Error
): AddOrderItemsToCartGraphQLResultAction => ({
  type: CartsActionTypes.AddOrderItemsToCartGraphQLResult,
  success,
  errorCode,
  errorMessage,
  orderId,
  error,
})

export interface AddOrderItemsInArrearsToCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.AddOrderItemsInArrearsToCartGraphQL
  input: AddOrderItemsInArrearsToCartInput
  selectedItemCount: number
}

export const addOrderItemsInArrearsToCartGraphQL = (
  input: AddOrderItemsInArrearsToCartInput,
  selectedItemCount: number
): AddOrderItemsInArrearsToCartGraphQLAction => ({
  type: CartsActionTypes.AddOrderItemsInArrearsToCartGraphQL,
  input,
  selectedItemCount,
})

export const AddOrderItemsInArrearsToCartGraphQLResult = (
  success: boolean,
  errorCode: string,
  errorMessage: string,
  error?: Error
): AddOrderItemsInArrearsToCartGraphQLResultAction => ({
  type: CartsActionTypes.AddOrderItemsInArrearsToCartGraphQLResult,
  success,
  errorCode,
  errorMessage,
  error,
})

export interface AddOrderItemsInArrearsToCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.AddOrderItemsInArrearsToCartGraphQLResult
  success: boolean
  errorCode: string
  errorMessage: string
  error?: Error
}

export interface AddOrReplaceOfferInCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.AddOrReplaceOfferInCartGraphQL
  offerId: string
  offerName: string
  selectedItemCount: number
  cartId: string
  overwrite: boolean
  include: Array<number>
  exclude: Array<number>
  amount: OfferAmount[]
  search: MultiSelectionSearch | null
}

/**
 * Action to add offer items via offerId to cart. OfferId set for cart as well as existing (diverse) offer items may be replaced/modified - this is managed via the overwrite-param.
 * @param offerId
 * @param offerName
 * @param totalOfferItemCount
 * @param cartId
 * @param overwrite Boolean which expresses if (existing) offerId in cart model gets (over)written. This affects line items contained within cart.
 * If true, diverse cart items get removed (Core-API implementation).
 * If false, diverse offer items will not be placed within cart (Core-API implementation).
 * @param include
 * @param exclude
 * @param amount
 * @param search
 */
export const addOrReplaceOfferInCartGraphQL = (
  offerId: string,
  offerName: string,
  totalOfferItemCount: number,
  cartId: string,
  overwrite: boolean,
  include: Array<number>,
  exclude: Array<number>,
  amount: OfferAmount[],
  search: MultiSelectionSearch | null
): AddOrReplaceOfferInCartGraphQLAction => ({
  type: CartsActionTypes.AddOrReplaceOfferInCartGraphQL,
  offerId,
  offerName,
  selectedItemCount: totalOfferItemCount,
  cartId,
  overwrite,
  include,
  exclude,
  amount,
  search,
})

export interface AddOrReplaceOfferInCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.AddOrReplaceOfferInCartGraphQLResult
  success: boolean
  error?: Error
}

export const addOrReplaceOfferInCartGraphQLResult = (
  success,
  error?
): AddOrReplaceOfferInCartGraphQLResultAction => ({
  type: CartsActionTypes.AddOrReplaceOfferInCartGraphQLResult,
  success,
  error,
})

export interface ChangeProductAmountGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.ChangeProductAmountGraphQL
  cartId: string
  itemId: string
  amount: number
  onResult?: () => void
}

export const changeProductAmountGraphQL = (
  cartId: string,
  itemId: string,
  amount: number,
  onResult?: () => void
): ChangeProductAmountGraphQLAction => ({
  type: CartsActionTypes.ChangeProductAmountGraphQL,
  cartId,
  itemId,
  amount,
  onResult,
})

export interface ChangeProductAmountGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.ChangeProductAmountGraphQLResult
  error?: Error
}

export const changeProductAmountGraphQLResult = (
  error?: Error
): ChangeProductAmountGraphQLResultAction => ({
  type: CartsActionTypes.ChangeProductAmountGraphQLResult,
  error,
})

type CartPatch = ShoppingCartUpdateMetaDataInput['cartMetaDataPatch']

export interface UpdateCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.UpdateCartGraphQL
  cart: CartPatch & { id: string }
}

export const updateCartGraphQL = (cart: CartPatch & { id: string }): UpdateCartGraphQLAction => ({
  type: CartsActionTypes.UpdateCartGraphQL,
  cart,
})

export interface SendCartAction extends CartsAction {
  readonly type: CartsActionTypes.SendCart
  cart: ShoppingCart
  skipCheck: boolean
}

export type SendCartResultType = 'submit' | 'notification'
interface DetailMessage {
  type: string
  message: string
}

export interface SendCartResultAction extends CartsAction {
  readonly type: CartsActionTypes.SendCartResult
  success: boolean
  resultType: SendCartResultType | undefined
  cart?: ShoppingCart
  messages?: DetailMessage[]
}

export interface UpdateCartArticleAmountAction extends CartsAction {
  readonly type: CartsActionTypes.UpdateCartArticleAmountAction
  cartId: string
  articleId: string
  newAmount: number
}

export const updateCartArticleAmount = (
  cartId: string,
  articleId: string,
  newAmount: number
): UpdateCartArticleAmountAction => ({
  type: CartsActionTypes.UpdateCartArticleAmountAction,
  cartId,
  articleId,
  newAmount,
})

export interface RemoveArticleFromCartAction extends CartsAction {
  readonly type: CartsActionTypes.RemoveArticleFromCartAction
  cartId: string
  articleId: string
}

export const removeArticleFromCart = (
  cartId: string,
  articleId: string
): RemoveArticleFromCartAction => ({
  type: CartsActionTypes.RemoveArticleFromCartAction,
  cartId,
  articleId,
})

export interface DeleteCartItemsGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.DeleteCartItemsGraphQL
  cart: ShoppingCartV2
  itemIds: string[]
  singleDelete: boolean
  imminentAddToCartWillFollow?: boolean
}

export const deleteCartItemsGraphQL = (
  cart: ShoppingCartV2,
  itemIds: string[],
  singleDelete: boolean,
  imminentAddToCartWillFollow?: boolean
): DeleteCartItemsGraphQLAction => ({
  type: CartsActionTypes.DeleteCartItemsGraphQL,
  cart,
  itemIds,
  singleDelete,
  imminentAddToCartWillFollow,
})

export interface DeleteCartItemsGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.DeleteCartItemsGraphQLResult
  cartEmpty: boolean
  deletedItemIds: string[]
  success: boolean
  error?: Error
}

export const DeleteCartItemsGraphQLResult = (
  cartEmpty: boolean,
  deletedItemIds: string[],
  success: boolean,
  error?: Error
): DeleteCartItemsGraphQLResultAction => ({
  type: CartsActionTypes.DeleteCartItemsGraphQLResult,
  cartEmpty,
  deletedItemIds: deletedItemIds,
  success,
  error,
})

export interface EmptyShoppingCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.EmptyShoppingCartGraphQL
  cart: ShoppingCartV2
}

export const emptyShoppingCartGraphQL = (cart: ShoppingCartV2): EmptyShoppingCartGraphQLAction => ({
  type: CartsActionTypes.EmptyShoppingCartGraphQL,
  cart,
})

export interface EmptyShoppingCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.EmptyShoppingCartGraphQLResult
  success: boolean
  error?: Error
}

export const EmptyShoppingCartGraphQLResult = (
  success: boolean,
  error?: Error
): EmptyShoppingCartGraphQLResultAction => ({
  type: CartsActionTypes.EmptyShoppingCartGraphQLResult,
  success,
  error,
})

export interface GetShoppingCartPricesGraphQLAction extends CartsAction {
  type: CartsActionTypes.GetShoppingCartPricesGraphQL
  cartId: string
  onResult?: () => void
}
export const getShoppingCartPrices = (
  cartId: string,
  onResult?: () => void
): GetShoppingCartPricesGraphQLAction => ({
  type: CartsActionTypes.GetShoppingCartPricesGraphQL,
  cartId,
  onResult,
})
export interface GetShoppingCartPricesGraphQLResultAction extends CartsAction {
  type: CartsActionTypes.GetShoppingCartPricesGraphQLResult
}

export const GetShoppingCartPricesGraphQLResult = (): GetShoppingCartPricesGraphQLResultAction => ({
  type: CartsActionTypes.GetShoppingCartPricesGraphQLResult,
})

export type VoltimumRequestItem = { sapId: string; amount: number }

export interface GetVoltimumPointsGraphQLAction extends CartsAction {
  type: CartsActionTypes.GetVoltimumPointsGraphQL
  items: VoltimumRequestItem[]
  onPointsCalculated: (sapIdsAndPoints: Array<{ sapId: string; points: number }>) => void
}

export const getVoltimumPoints = (
  items: VoltimumRequestItem[],
  onPointsCalculated: (sapIdsAndPoints: Array<{ sapId: string; points: number }>) => void
): GetVoltimumPointsGraphQLAction => ({
  type: CartsActionTypes.GetVoltimumPointsGraphQL,
  items,
  onPointsCalculated,
})

export interface GetVoltimumPointsGraphQLResultAction extends CartsAction {
  type: CartsActionTypes.GetVoltimumPointsGraphQLResult
}

export const GetVoltimumPointsGraphQLResult = (): GetVoltimumPointsGraphQLResultAction => ({
  type: CartsActionTypes.GetVoltimumPointsGraphQLResult,
})

export interface ResetShoppingCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.ResetShoppingCartGraphQL
  cart: ShoppingCartV2
}

export const resetShoppingCartGraphQL = (cart: ShoppingCartV2): ResetShoppingCartGraphQLAction => ({
  type: CartsActionTypes.ResetShoppingCartGraphQL,
  cart,
})

export interface ResetShoppingCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.ResetShoppingCartGraphQLResult
  success: boolean
  error?: Error
}

export const ResetShoppingCartGraphQLResult = (
  success: boolean,
  error?: Error
): ResetShoppingCartGraphQLResultAction => ({
  type: CartsActionTypes.ResetShoppingCartGraphQLResult,
  success,
  error,
})

export interface NotifyCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.NotifyCartGraphQL
  cartId: string
}

export const notifyCartGraphQL = (cartId: string): NotifyCartGraphQLAction => ({
  type: CartsActionTypes.NotifyCartGraphQL,
  cartId,
})

export interface NotifyCartGraphQLResultAction extends CartsAction {
  notificationEmail: string
  error?: Error
}

export const notifyCartGraphQLResult = (
  notificationEmail: string,
  error?: Error
): NotifyCartGraphQLResultAction => ({
  type: CartsActionTypes.NotifyCartGraphQLResult,
  notificationEmail,
  error,
})

export interface SubmitCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.SubmitCartGraphQL
  cartId: string
}

export const submitCartGraphQL = (cartId: string): SubmitCartGraphQLAction => ({
  type: CartsActionTypes.SubmitCartGraphQL,
  cartId,
})

export interface SubmitCartGraphQLResultAction extends CartsAction {
  success: boolean
  error?: Error
}

export const submitCartGraphQLResult = (
  success: boolean,
  error?: Error
): SubmitCartGraphQLResultAction => ({
  type: CartsActionTypes.SubmitCartGraphQLResult,
  success,
  error,
})

export interface VerifyOfferInCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.VerifyOfferInCartInOfferGraphQL
  cartId: string
}

export const verifyOfferInCartGraphQL = (cartId: string): VerifyOfferInCartGraphQLAction => ({
  type: CartsActionTypes.VerifyOfferInCartInOfferGraphQL,
  cartId,
})

export interface VerifyOfferInCartGraphQLResultAction extends CartsAction {
  success: boolean
  result: VerifyOfferInShoppingCartResult
  error?: Error
}

export const verifyCartInOfferGraphQLResult = (
  success: boolean,
  result: VerifyOfferInShoppingCartResult,
  error?: Error
): VerifyOfferInCartGraphQLResultAction => ({
  type: CartsActionTypes.VerifyOfferInCartGraphQLResult,
  success,
  result,
  error,
})

export interface MoveCartItemsGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.MoveCartItemsGraphQL
  itemIds: string[]
  sourceCart: ShoppingCartV2
  targetCartId: string
  MoveCartItemsOfferIdUpdateEnum: MoveCartItemsOfferIdUpdateEnum
  offerId?: string
}

export const moveCartItemsGraphQL = (
  itemIds: string[],
  sourceCart: ShoppingCartV2,
  targetCartId: string,
  MoveCartItemsOfferIdUpdateEnum: MoveCartItemsOfferIdUpdateEnum,
  offerId?: string
): MoveCartItemsGraphQLAction => ({
  type: CartsActionTypes.MoveCartItemsGraphQL,
  itemIds,
  sourceCart: sourceCart,
  targetCartId,
  MoveCartItemsOfferIdUpdateEnum,
  offerId,
})

export interface MoveCartItemsGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.MoveCartItemsGraphQLResult
  cartEmpty: boolean
  movedItemIds: string[]
  success: boolean
  error?: Error
}

export const moveCartItemsGraphQLResult = (
  cartEmpty: boolean,
  movedItemIds: string[],
  success: boolean,
  error?: Error
): MoveCartItemsGraphQLResultAction => ({
  type: CartsActionTypes.MoveCartItemsGraphQLResult,
  cartEmpty,
  movedItemIds,
  success,
  error,
})

export interface VerifyCartGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.VerifyCartGraphQL
  cartId: string
  pollInterval?: number
}
export const verifyCartGraphQL = (
  cartId: string,
  pollInterval?: number
): VerifyCartGraphQLAction => ({
  type: CartsActionTypes.VerifyCartGraphQL,
  cartId,
  pollInterval,
})

export interface VerifyCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.VerifyCartGraphQLResult
  results: [
    {
      errorCode: string
      errorType: string
      message: string
      lineItemIds: [string]
    }
  ]
}

export const VerifyCartGraphQLResult = (
  results: [
    {
      errorCode: string
      errorType: string
      message: string
      lineItemIds: [string]
    }
  ]
): VerifyCartGraphQLResultAction => ({
  type: CartsActionTypes.VerifyCartGraphQLResult,
  results,
})

export interface AddMarkForNotificationAction extends CartsAction {
  readonly type: CartsActionTypes.AddMarkForNotification
  cartId: string
}

export const addMarkForNotification = (cartId: string) => {
  return {
    type: CartsActionTypes.AddMarkForNotification,
    cartId,
  }
}

export interface RemoveMarkForNotificationAction extends CartsAction {
  readonly type: CartsActionTypes.RemoveMarkForNotification
  cartId: string
}

export const removeMarkForNotification = (cartId: string) => {
  return {
    type: CartsActionTypes.RemoveMarkForNotification,
    cartId,
  }
}

export interface UpdateCartGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.UpdateCartGraphQLResult
  success: boolean
  error?: Error
}

export const UpdateCartGraphQLResult = (
  success: boolean,
  error?: Error
): UpdateCartGraphQLResultAction => ({
  type: CartsActionTypes.UpdateCartGraphQLResult,
  success,
  error,
})
export const UpdateShoppingCartPricesGraphQLResult =
  (): UpdateCustomProductsGraphQLResultAction => ({
    type: CartsActionTypes.UpdateCustomProductsGraphQLResult,
  })
export interface UpdateCustomProductsGraphQLAction extends CartsAction {
  readonly type: CartsActionTypes.UpdateCustomProductsGraphQL
  cart: ShoppingCartV2
  shoppingCartItems: ShoppingCartItem[]
}

export interface UpdateCustomProductsGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.UpdateCustomProductsGraphQLResult
  error?: Error
}

export type UpdateCustomProductResponse = {
  getOfferItemTitles: [{ sapId: string; offerItemPosition: number; title: string }]
}
export const geCustomProductsGraphQL = (
  cart: ShoppingCartV2,
  shoppingCartItems: ShoppingCartItem[]
) => ({
  type: CartsActionTypes.UpdateCustomProductsGraphQL,
  cart,
  shoppingCartItems,
})

export interface GetIdsFormFieldsGraphQLAction extends CartsAction {
  type: CartsActionTypes.GetIdsFormFieldsGraphQL
  cartId: string
}

export const getIdsFormFields = (cartId: string): GetIdsFormFieldsGraphQLAction => ({
  type: CartsActionTypes.GetIdsFormFieldsGraphQL,
  cartId,
})

export interface GetIdsFormFieldsGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.GetIdsFormFieldsGraphQLResult
  idsFormFieldsResult: IdsFormFieldsResult
  error?: Error
}

export const getIdsFormFieldsResult = (
  idsFormFieldsResult: IdsFormFieldsResult,
  error?: Error
): GetIdsFormFieldsGraphQLResultAction => ({
  type: CartsActionTypes.GetIdsFormFieldsGraphQLResult,
  idsFormFieldsResult,
  error,
})

export interface GetOciFormFieldsGraphQLAction extends CartsAction {
  type: CartsActionTypes.GetOciFormFieldsGraphQL
  cartId: string
}

export const getOciFormFields = (cartId: string): GetOciFormFieldsGraphQLAction => ({
  type: CartsActionTypes.GetOciFormFieldsGraphQL,
  cartId,
})

export interface GetOciFormFieldsGraphQLResultAction extends CartsAction {
  readonly type: CartsActionTypes.GetOciFormFieldsGraphQLResult
  ociFormFieldsResult: OciCartFormFieldsResponse
  error?: Error
}

export const getOciFormFieldsResult = (
  ociFormFieldsResult: OciCartFormFieldsResponse,
  error?: Error
): GetOciFormFieldsGraphQLResultAction => ({
  type: CartsActionTypes.GetOciFormFieldsGraphQLResult,
  ociFormFieldsResult,
  error,
})

export interface AddToCartFromIdsXmlAction extends CartsAction {
  readonly type: CartsActionTypes.AddToCartFromIdsXml
  xmlCart: string
  cartId: string
}

export const addToCartFromIdsXml = (
  xmlCart: string,
  cartId: string
): AddToCartFromIdsXmlAction => ({
  type: CartsActionTypes.AddToCartFromIdsXml,
  xmlCart,
  cartId,
})

export interface AddToCartFromIdsXmlResultAction extends CartsAction {
  readonly type: CartsActionTypes.AddToCartFromIdsXmlResult
  result: ShoppingCartUpdateResultFromSchema
}

export const addToCartFromIdsXmlResult = (
  result: ShoppingCartUpdateResultFromSchema
): AddToCartFromIdsXmlResultAction => ({
  type: CartsActionTypes.AddToCartFromIdsXmlResult,
  result,
})

export interface ManuallyUpdateCartsActions extends CartsAction {
  readonly type: CartsActionTypes.ManuallyUpdateCarts
}

export interface ManuallyUpdateCartsResultActions extends CartsAction {
  readonly type: CartsActionTypes.ManuallyUpdateCartsResult
  carts: CartFromManualUpdate[]
}

export const manuallyUpdateCarts = (): ManuallyUpdateCartsActions => ({
  type: CartsActionTypes.ManuallyUpdateCarts,
})

export const manuallyUpdateCartsResult = (
  carts: CartFromManualUpdate[]
): ManuallyUpdateCartsResultActions => ({
  carts,
  type: CartsActionTypes.ManuallyUpdateCartsResult,
})
