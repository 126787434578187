import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { IListOption } from '../../types'
import { profileDropdownItems, profileDropdownItemsNative } from '../../constants'
import { ReactComponent as MailIcon } from 'assets/icon/designsystem/mail.svg'
import { TFunction } from 'i18next'
import { useUnreadMessages } from '@obeta/data/lib/hooks/useUnreadMessages'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { UserType } from '@obeta/models/lib/models/Users/UserV2'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { OptionWithNewLabel } from './OptionWithNewLabel'
export const MESSAGE_CENTER = '/message-center'

export const useProfileDropdownOptions = () => {
  const { isLoggedIn, user, permissions } = useUserDataV2()
  const isNativeUiTarget = isUiTarget('app')
  const { t } = useTranslation()
  const unreadMessages = useUnreadMessages()
  const unreadMessagesCount = unreadMessages.length
  const isTecselectIntegrationEnabled = useFeatureToggle('useTecselectIntegration')
  const useNewUserManagement = useFeatureToggle('UseNewUserManagement')

  const items = useMemo(() => {
    return isNativeUiTarget ? profileDropdownItemsNative : profileDropdownItems
  }, [isNativeUiTarget])

  return useMemo<IListOption[]>(() => {
    if (!isLoggedIn) {
      return items.notLoggedIn
    }

    let options: IListOption[] = [...items.loggedIn]

    if (useNewUserManagement) {
      if (permissions && permissions.Global_canSeeUserManagement) {
        options.splice(2, 0, {
          id: 'userOverview',
          element: (
            <OptionWithNewLabel>{t('USER_MANAGEMENT.NEW_USER_MANAGEMENT')}</OptionWithNewLabel>
          ),
          url: ShopRoutes.UserOverview,
          name: t('USER_MANAGEMENT.NEW_USER_MANAGEMENT'),
        })
      } else {
        options.splice(2, 0, {
          id: 'userOverview',
          element: <OptionWithNewLabel>{t('USER_MANAGEMENT.MY_PROFILE')}</OptionWithNewLabel>,
          url: `${ShopRoutes.UserManagement}/${user?.customerNumber}`,
          name: t('USER_MANAGEMENT.MY_PROFILE'),
        })
      }
      options = options.filter((option) => option.id !== 2)
    }

    if (!permissions?.User_canReadAccounting) {
      options = options.filter((option) => {
        return option.id !== 5
      })
    }

    if (isNativeUiTarget) {
      const messagesOption = createMessagesListOption({ t, unreadMessagesCount })
      options.push(messagesOption)
    }

    return options
      .filter((option) => {
        if (option.id !== 'tecselect') return true
        return isTecselectIntegrationEnabled && user?.isTecselectUser && user.type === UserType.main
      })
      .map((option) => {
        if (option.id !== 'tecselect') return option
        return { ...option, url: user?.tecselectLoginUrl ?? '' }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn,
    isNativeUiTarget,
    t,
    unreadMessagesCount,
    isTecselectIntegrationEnabled,
    user?.isTecselectUser,
  ])
}

function createMessagesListOption(deps: ICreateMessagesListOptionDeps): IListOption {
  const { t, unreadMessagesCount } = deps
  return {
    id: 'messages',
    url: MESSAGE_CENTER,
    name: [
      t('MESSAGE_CENTER.MENU_ITEM'),
      unreadMessagesCount ? `(${unreadMessagesCount})` : '',
    ].join(' '),
    icon: <MailIcon />,
  }
}

interface ICreateMessagesListOptionDeps {
  t: TFunction
  unreadMessagesCount: number
}
