import { FC } from 'react'
import { ListItem, ListItemButton, ListItemText } from '@mui/material'
import clsx from 'clsx'
import styles from './AllCategory.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as ChevronRight } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as RedLogo } from 'assets/icon/designsystem/red-logo.svg'
import { buildSearchUrlWithSupplierFilter } from '@obeta/utils/lib/search/buildSearchUrlWithSupplierFilter'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useTranslation } from 'react-i18next'

const RED_CATEGORY_ID = 'red-search-link-category'
const SUPPLIER_RED_VALUE = 'DEHARED'

export const RedCategoryItem: FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const { desktop } = useBreakpoints()
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <ListItem
      id={RED_CATEGORY_ID}
      key={RED_CATEGORY_ID}
      className={clsx({
        [styles.dehaCategoryListItem]: desktop,
        [styles.paddingTopAndBottomOneRem]: desktop,
        [styles.listItemMobile]: !desktop,
      })}
    >
      <RedLogo />
      <ListItemButton
        className={clsx(styles.listItemButton, {
          [styles.paddingLeftHalfRem]: !desktop,
          [styles.noPaddingRight]: !desktop,
        })}
        onClick={() => {
          const searchUrl = buildSearchUrlWithSupplierFilter([SUPPLIER_RED_VALUE])
          if (searchUrl) {
            closeMenu()
            history.push(searchUrl)
          }
        }}
      >
        <ListItemText
          primary={t('START.RED_PRODUCT_RANGE')}
          primaryTypographyProps={{ variant: 'bodyBold' }}
          className={clsx(styles.listItemText, { [styles.fontWeight400]: !desktop })}
        />
        {!desktop && <ChevronRight />}
      </ListItemButton>
    </ListItem>
  )
}
