import { ofType } from 'redux-observable'
import {
  catchError,
  concatMap,
  debounce,
  defer,
  of,
  map,
  mergeMap,
  Observable,
  retry,
  switchMap,
  tap,
  timer,
} from 'rxjs'
import {
  AddCartTemplatesToCartGraphQLAction,
  AddMarkForNotificationAction,
  CartsActionTypes,
  ChangeProductAmountGraphQLAction,
  changeProductAmountGraphQLResult,
  DeleteCartItemsGraphQLAction,
  DeleteCartItemsGraphQLResult,
  EmptyShoppingCartGraphQLAction,
  EmptyShoppingCartGraphQLResult,
  GetShoppingCartPricesGraphQLAction,
  GetShoppingCartPricesGraphQLResult,
  MoveCartItemsGraphQLAction,
  moveCartItemsGraphQLResult,
  NotifyCartGraphQLAction,
  notifyCartGraphQLResult,
  SubmitCartGraphQLAction,
  submitCartGraphQLResult,
  ResetShoppingCartGraphQLAction,
  ResetShoppingCartGraphQLResult,
  UpdateCartGraphQLAction,
  UpdateCartGraphQLResult,
  AddProductToCartGraphQLAction,
  addProductToCartGraphQLResult,
  VerifyCartGraphQLAction,
  VerifyCartGraphQLResult,
  RemoveMarkForNotificationAction,
  AddCartContextEnum,
  UpdateCustomProductsGraphQLAction,
  UpdateShoppingCartPricesGraphQLResult,
  UpdateCustomProductResponse,
  GetVoltimumPointsGraphQLAction,
  GetVoltimumPointsGraphQLResult,
  addCartTemplatesToCartGraphQLResult,
  AddOrReplaceOfferInCartGraphQLAction,
  addOrReplaceOfferInCartGraphQLResult,
  AddCartTemplateItemsToCartGraphQLAction,
  addCartTemplateItemsToCartGraphQLResult,
  GetIdsFormFieldsGraphQLAction,
  getIdsFormFieldsResult,
  getOciFormFieldsResult,
  AddToCartFromIdsXmlAction,
  addToCartFromIdsXmlResult,
  AddOrderItemsToCartGraphQLAction,
  AddOrderItemsToCartGraphQLResult,
  VerifyOfferInCartGraphQLAction,
  verifyCartInOfferGraphQLResult,
  ManuallyUpdateCartsActions,
  manuallyUpdateCartsResult,
  GetOciFormFieldsGraphQLAction,
  AddOrderItemsInArrearsToCartGraphQLAction,
  AddOrderItemsInArrearsToCartGraphQLResult,
} from '../actions/cart-actions'
import { Haptics } from '@capacitor/haptics'
import {
  CartMoveItemsToAdd,
  MoveCartItemsOfferIdUpdateEnum,
  ShoppingCartAddItemsInput,
  ShoppingCartItem,
  ShoppingCartUpdateOutput,
  ShoppingCartUpdateResult,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { noop } from '../actions'
import { CollectionsOfDatabase, RxDatabase, RxDocument } from 'rxdb'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  moveItemsToCart,
  deleteShoppingCartItems,
  notifyShoppingCart,
  submitShoppingCart,
  updateShoppingCartItems,
  addItemsToCart,
  updateShoppingCartMetaDataMutation,
  verifyOfferInShoppingCart,
} from '../entities/cartsv2QueryProps'
import { OFFER_ITEM_TITLES } from '../queries/offerItemTitles'
import {
  AddCartTemplateItemsToCartOutput,
  AddOrderItemsInArrearsToCartMutation,
  AddOrderItemsInArrearsToCartMutationVariables,
  AddOrderItemsToCartMutation,
  AddOrderItemsToCartMutationVariables,
  AddOrReplaceOfferInCartMutation,
  AddOrReplaceOfferInCartMutationVariables,
  AddToCartFromIdsXmlMutation,
  AddToCartFromIdsXmlMutationVariables,
  GetProductsQuery,
  GetProductsQueryVariables,
  GetShoppingCartsQuery,
  GetShoppingCartsQueryVariables,
  GetVoltimumPointsQuery,
  GetVoltimumPointsQueryVariables,
  IdsFormFieldsResult,
  OciCartFormFieldsResponse,
  ShoppingCartUpdateResult as ShoppingCartUpdateResultFromSchema,
  UpdateShoppingCartMetaDataMutation,
  UpdateShoppingCartMetaDataMutationVariables,
} from '@obeta/schema'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { trackClick } from '@obeta/utils/lib/tracking'
import featureToggleService from '../hooks/feature-toggles/FeatureToggleService'
import { GET_SHOPPING_CARTS } from '../queries/getShoppingCarts'
import {
  isCancelledOrCustomProductError,
  isUnpurchasableProductError,
} from '@obeta/utils/lib/notification'
import { isMobile } from '@obeta/utils/lib/isMobile'

/*
The naming createChangeProductAmountEffect has been selected to prevent confusion with UpdateCartArticleAmountAction which is currently being used in the App
 */
export const createChangeProductAmountEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<ChangeProductAmountGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.ChangeProductAmountGraphQL),
      debounce(() => timer(450)),
      switchMap((action: ChangeProductAmountGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'cartsv2', { isUpdating: true, affectedItem: action.itemId }) // show skeleton for item, switch back will take place in getShoppingCartPricesGraphQL() below

          await apolloClient.mutate<ShoppingCartUpdateOutput>({
            mutation: updateShoppingCartItems,
            variables: {
              cartId: action.cartId,
              cartItemPatches: [
                {
                  itemId: action.itemId,
                  patch: { amount: action.amount },
                },
              ],
            },
          })
          if (action.onResult) {
            action.onResult()
          }
          return action$
        }).pipe(retry(1))
      ),
      map(() => changeProductAmountGraphQLResult()),
      catchError((error) => {
        error.message = 'error in ' + createChangeProductAmountEffect.name + ' ' + error.message
        handleError(error)
        return of(changeProductAmountGraphQLResult(error))
      })
    )
}

export const createUpdateCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.UpdateCartGraphQL),
      switchMap((action: UpdateCartGraphQLAction) =>
        defer(async () => {
          const backupCart = await db.cartsv2.findOne(action.cart.id).exec()
          await db.cartsv2.upsert({ ...action.cart, isUpdating: true })
          const input = {
            cartId: action.cart.id,
            cartMetaDataPatch: {
              offerId: action.cart.offerId,
              promotionId: action.cart.promotionId,
              commission: action.cart.commission,
              remark: action.cart.remark,
              phone: action.cart.phone,
              // only add paymentMethod to patch if feature is enabled
              ...(featureToggleService.getFeatureToggleValue('UsePaymentProvider') && {
                paymentMethod: action.cart.paymentMethod,
              }),
            },
          }

          if (action.cart.shippingData) {
            const deliveryAddress = { ...action.cart.shippingData.deliveryAddress }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- __typename doesn't exist in manually created model
            delete (deliveryAddress as any).__typename
            input.cartMetaDataPatch['shippingData'] = {
              isCompleteDelivery: action.cart.shippingData.isCompleteDelivery,
              deliveryAddress: deliveryAddress,
              shippingDate: action.cart.shippingData.shippingDate,
              shippingType: action.cart.shippingData.shippingType,
              storeId: action.cart.shippingData.storeId,
              addressId: action.cart.shippingData.addressId,
            }
          }

          const response = await apolloClient.mutate<
            UpdateShoppingCartMetaDataMutation,
            UpdateShoppingCartMetaDataMutationVariables
          >({
            mutation: updateShoppingCartMetaDataMutation,
            variables: {
              input,
            },
          })

          if (!response.data?.updateShoppingCartMetaData.updateResults?.success) {
            await db.cartsv2.upsert({ ...backupCart.toJSON(), isUpdating: false })
          } else {
            await db.cartsv2.upsert({ ...action.cart, isUpdating: false })
          }
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result: UpdateShoppingCartMetaDataMutation) =>
            of(UpdateCartGraphQLResult(result?.updateShoppingCartMetaData.updateResults?.success))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + createUpdateCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(UpdateCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createUpdateCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(UpdateCartGraphQLResult(false, error))
      })
    )
}

export const moveCartItemsGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<MoveCartItemsGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.MoveCartItemsGraphQL),
      concatMap((action: MoveCartItemsGraphQLAction) =>
        defer(async () => {
          const targetCartId = action.targetCartId
          const targetCart = await db.cartsv2.findOne(action.targetCartId).exec()

          // ----- Step 1 - UPDATE OFFERID FOR TARGET CART if needed ---
          const offerUpdatesRequired = action.MoveCartItemsOfferIdUpdateEnum
          let updateCartResponse
          if (
            offerUpdatesRequired ===
            MoveCartItemsOfferIdUpdateEnum.ResetItemOfferIdsAndUpdateTargetOfferId
          ) {
            updateCartResponse = await apolloClient.mutate<ShoppingCartUpdateOutput>({
              mutation: updateShoppingCartMetaDataMutation,
              variables: {
                input: {
                  cartId: targetCartId,
                  cartMetaDataPatch: {
                    offerId: action.offerId,
                  },
                },
              },
            })
          }

          // ----- Step 2 - ADD ITEMS TO TARGET CART ---
          const sourceCartItemsCount = action.sourceCart.items.length

          const itemsToAddToTargetCart: CartMoveItemsToAdd[] = []
          // We purely need the sapIds to be able to map back to the itemIds of the source cart
          const sapIdsOfCartItemsAdded: string[] = []
          const itemIdsOfCartItemsAddedToTargetCart: string[] = []

          action.itemIds.forEach((itemId) => {
            const cartItem = action.sourceCart.items.find((item) => item.id === itemId)

            if (cartItem) {
              itemsToAddToTargetCart.push({
                sapId: cartItem.sapId,
                amount: cartItem.amount,
                offerId:
                  offerUpdatesRequired === MoveCartItemsOfferIdUpdateEnum.NoUpdates
                    ? action.sourceCart.offerId
                    : '',
                offerItemPosition: '', // TODO Needs to be set. Yet unclear where we get the offerItemPosition from.
              })
            }
          })

          let addItemsResponse
          if (itemsToAddToTargetCart.length > 0) {
            addItemsResponse = await apolloClient.mutate({
              mutation: moveItemsToCart,
              variables: {
                input: {
                  cartId: targetCartId,
                  items: itemsToAddToTargetCart,
                },
              },
            })
            if (addItemsResponse?.data?.addShoppingCartItems?.updateResults.success) {
              itemsToAddToTargetCart.forEach((item) => {
                sapIdsOfCartItemsAdded.push(item.sapId)
              })
            } else {
              addItemsResponse?.data.addShoppingCartItems?.itemResults.forEach((itemResult) => {
                if (itemResult.success) {
                  itemsToAddToTargetCart.forEach((itemResult) => {
                    sapIdsOfCartItemsAdded.push(itemResult.sapId)
                  })
                }
              })
            }
          }

          // ----- Step 3 - DELETE MOVED ITEMS FROM sourceCart
          let sapIdsOfCartItemsMoved: string[] = []
          let itemIdsOfCartItemsMoved: string[] = []

          // Optimistic DB update
          const backupOfSourceCart = await db.cartsv2.findOne(action.sourceCart.id).exec()
          const optimisticallyUpdatedItems: ShoppingCartItem[] = action.sourceCart.items.filter(
            (item) => !sapIdsOfCartItemsAdded.includes(item.sapId)
          )

          const optimisticallyUpdatedCart: ShoppingCartV2 = { ...action.sourceCart }
          optimisticallyUpdatedCart.items = optimisticallyUpdatedItems
          await db.cartsv2.upsert(optimisticallyUpdatedCart)

          // Map from sap Ids back to item ids of the source cart
          const cartItemsAdded = action.sourceCart.items.filter((shoppingCartItem) =>
            sapIdsOfCartItemsAdded.includes(shoppingCartItem.sapId)
          )
          cartItemsAdded.forEach((item) => itemIdsOfCartItemsAddedToTargetCart.push(item.id))

          const deleteItemsResponse = await apolloClient.mutate({
            mutation: deleteShoppingCartItems,
            variables: {
              input: {
                cartId: action.sourceCart.id,
                itemIds: itemIdsOfCartItemsAddedToTargetCart,
              },
            },
          })

          const allDeletesFromSourceCartWereSuccessful =
            deleteItemsResponse.data.deleteShoppingCartItems.updateResults.success
          if (allDeletesFromSourceCartWereSuccessful) {
            itemIdsOfCartItemsMoved = action.itemIds
            sapIdsOfCartItemsMoved = sapIdsOfCartItemsAdded
          }

          // Revert optimistic DB update for items which were not actually deleted from the source cart
          if (!allDeletesFromSourceCartWereSuccessful) {
            deleteItemsResponse?.data?.deleteShoppingCartItems.updateResults.itemResults.forEach(
              (cartItemResult, index) => {
                if (cartItemResult.success) {
                  itemIdsOfCartItemsMoved.push(cartItemResult.id)
                  sapIdsOfCartItemsMoved.push(sapIdsOfCartItemsAdded[index])
                }
              }
            )
            if (sapIdsOfCartItemsAdded.length !== sapIdsOfCartItemsMoved.length) {
              backupOfSourceCart.items = backupOfSourceCart.items.filter(
                (item) => !itemIdsOfCartItemsMoved.includes(item.id)
              )
            }
            await db.cartsv2.upsert(backupOfSourceCart.toJSON())
          }

          // ----- Step 4 - TRIGGER RENDER OF NotificationCartMove
          let sourceCartEmpty = false
          if (sourceCartItemsCount - sapIdsOfCartItemsMoved.length === 0) {
            sourceCartEmpty = true
          }

          if (sapIdsOfCartItemsMoved.length > 0) {
            let notificationId = targetCartId
            sapIdsOfCartItemsMoved.forEach(
              (sapIdOfMovedItem) => (notificationId += `-${sapIdOfMovedItem}`)
            )

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.CartMove,
              id: notificationId,
              options: {
                cartEmpty: sourceCartEmpty,
                cartName: targetCart.name,
                includingOffer:
                  updateCartResponse?.data?.updateShoppingCartMetaData?.updateResults?.success,
                itemCount: sapIdsOfCartItemsMoved.length,
              },
            })
          }
          // Note: Product decided that we don't show any user notice in case none of the items were moved = failure.

          deleteItemsResponse.data.cartEmpty = sourceCartEmpty
          deleteItemsResponse.data.movedItemIds = itemIdsOfCartItemsMoved
          deleteItemsResponse.data.success =
            itemIdsOfCartItemsMoved.length === action.itemIds.length
          return deleteItemsResponse.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(moveCartItemsGraphQLResult(result.cartEmpty, result.movedItemIds, result.success))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + moveCartItemsGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(moveCartItemsGraphQLResult(false, [], true, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + moveCartItemsGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(moveCartItemsGraphQLResult(false, [], false, error))
      })
    )
}

export const notifyCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<NotifyCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.NotifyCartGraphQL),
      concatMap((action: NotifyCartGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'cartsv2', { isUpdating: true })

          const notifyCartResponse = await apolloClient.mutate({
            mutation: notifyShoppingCart,
            variables: {
              input: {
                cartId: action.cartId,
              },
            },
          })

          await changeMetaData(db, 'cartsv2', { isUpdating: false })

          return notifyCartResponse.data.notifyShoppingCart
        }).pipe(
          retry(1),
          mergeMap((result) => of(notifyCartGraphQLResult(result.notificationEmail))),
          catchError((error) => {
            error.message =
              'error while processing ' + notifyCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(notifyCartGraphQLResult('', error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + notifyCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(notifyCartGraphQLResult('', error))
      })
    )
}

export const submitCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<SubmitCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.SubmitCartGraphQL),
      concatMap((action: SubmitCartGraphQLAction) =>
        defer(async () => {
          const submitCartResponse = await apolloClient.mutate({
            mutation: submitShoppingCart,
            variables: {
              input: {
                cartId: action.cartId,
                userAgent: isPlatform('web') ? navigator.userAgent : 'obeta-app',
              },
            },
          })

          return submitCartResponse.data
        }).pipe(
          mergeMap((result) => of(submitCartGraphQLResult(result.success))),
          catchError((error) => {
            error.message =
              'error while processing ' + submitCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(submitCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + submitCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(submitCartGraphQLResult(false, error))
      })
    )
}

export const verifyCartInOfferGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<VerifyOfferInCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.VerifyOfferInCartInOfferGraphQL),
      concatMap((action: VerifyOfferInCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.query({
            query: verifyOfferInShoppingCart,
            variables: {
              input: {
                cartId: action.cartId,
              },
            },
          })
          return response.data.verifyOfferInShoppingCart
        }).pipe(
          mergeMap((result) => of(verifyCartInOfferGraphQLResult(result.success, result.result))),
          catchError((error) => {
            error.message =
              'error while processing ' + verifyCartInOfferGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(
              verifyCartInOfferGraphQLResult(
                false,
                { removedCartItems: [], addedRelations: [], removedRelations: [] },
                error
              )
            )
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + verifyCartInOfferGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(
          verifyCartInOfferGraphQLResult(
            false,
            { removedCartItems: [], addedRelations: [], removedRelations: [] },
            error
          )
        )
      })
    )
}

const singleItemsRemoveStorage = new Set<string>()

export const isSingleCartRemoveInProgress = () => {
  return singleItemsRemoveStorage.size > 0
}

export const deleteCartItemsGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<DeleteCartItemsGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.DeleteCartItemsGraphQL),
      concatMap((action: DeleteCartItemsGraphQLAction) =>
        defer(async () => {
          // The variables below are needed for the notification shown to user in case of success
          let deletedItemIds = [] as unknown as string[]
          const failedItemIds = [] as unknown as string[]
          let productTitle = ''
          if (action.singleDelete) {
            const cartItem = action.cart.items.find((item) => {
              return item.id === action.itemIds[0]
            })
            productTitle = (cartItem && cartItem.product.title) ?? ''

            singleItemsRemoveStorage.add(action.itemIds[0])
          }

          // Optimistic DB update to trigger visual feedback for user
          const backupCart = await db.cartsv2.findOne(action.cart.id).exec()
          const optimisticallyUpdatedItems: ShoppingCartItem[] = action.cart.items.filter(
            (item) => !action.itemIds.includes(item.id)
          )
          const optimisticallyUpdatedCart: ShoppingCartV2 = { ...action.cart }
          optimisticallyUpdatedCart.items = optimisticallyUpdatedItems
          await db.cartsv2.upsert(optimisticallyUpdatedCart)

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation deleteShoppingCartItemsInline($input: ShoppingCartDeleteItemsInput!) {
                deleteShoppingCartItems(input: $input) {
                  updateResults {
                    success
                    errorMessage
                    itemResults {
                      success
                      errorMessage
                    }
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cart.id,
                itemIds: action.itemIds,
              },
            },
          })

          // all deletes were successful
          if (response.data.deleteShoppingCartItems.updateResults.success) {
            deletedItemIds = action.itemIds
          }

          // only some deletes were successful
          if (!response.data.deleteShoppingCartItems.updateResults.success) {
            response?.data?.deleteShoppingCartItems.updateResults.itemResults.forEach(
              (itemResult, index) => {
                if (itemResult.success) {
                  deletedItemIds.push(action.itemIds[index])
                } else {
                  failedItemIds.push(action.itemIds[index])
                }
              }
            )

            if (action.itemIds.length !== failedItemIds.length) {
              backupCart.items = backupCart.items.filter(
                (item) => !deletedItemIds.includes(item.id)
              )
            }
            await db.cartsv2.upsert(backupCart.toJSON())
          }

          const syncedCart = await db.cartsv2.findOne(action.cart.id).exec()
          const cartEmpty = syncedCart.items.length === 0 && !action.imminentAddToCartWillFollow

          if (
            response?.data?.deleteShoppingCartItems.updateResults.success &&
            action.singleDelete
          ) {
            singleItemsRemoveStorage.delete(action.itemIds[0])

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteArticleSingle,
              id: action.itemIds[0],
              options: {
                id: action.itemIds[0],
                cartEmpty: cartEmpty,
                cartId: action.cart.id,
                productTitle: productTitle, // shoppingCartItem.product.title,
              },
            })
          }

          // trigger render of NotificationDeleteArticleMultiple
          if (
            response?.data?.deleteShoppingCartItems.updateResults.success &&
            !action.singleDelete
          ) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteArticleMultiple,
              id: action.itemIds[0],
              options: {
                id: action.itemIds[0],
                cartEmpty: cartEmpty,
                cartId: action.cart.id,
                itemCount: deletedItemIds.length,
              },
            })
          }

          response.data.cartEmpty = cartEmpty
          response.data.deletedItemIds = deletedItemIds
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              DeleteCartItemsGraphQLResult(
                result.cartEmpty,
                result.deletedItemIds,
                result?.deleteShoppingCartItems.updateResults?.success
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + deleteCartItemsGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(DeleteCartItemsGraphQLResult(false, [], error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + deleteCartItemsGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(DeleteCartItemsGraphQLResult(false, [], error))
      })
    )
}

export const emptyShoppingCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<EmptyShoppingCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.EmptyShoppingCartGraphQL),
      concatMap((action: EmptyShoppingCartGraphQLAction) =>
        defer(async () => {
          // Optimistic DB update to trigger visual feedback for user
          const backupCart: RxDocument<ShoppingCartV2> = await db.cartsv2
            .findOne(action.cart.id)
            .exec()
          const optimisticallyUpdatedCart: ShoppingCartV2 = { ...action.cart }
          optimisticallyUpdatedCart.items = []
          await db.cartsv2.upsert(optimisticallyUpdatedCart)

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation emptyShoppingCart($input: ShoppingCartEmptyInput!) {
                emptyShoppingCart(input: $input) {
                  updateResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cart.id,
              },
            },
          })

          // clearing was not successfull
          if (!response.data.emptyShoppingCart.updateResults.success) {
            await db.cartsv2.upsert(backupCart.toJSON())
          }

          if (response?.data?.emptyShoppingCart?.updateResults?.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.EmptyCart,
              id: `${action.cart.id}-${NotificationType.EmptyCart}`,
              options: {
                cartId: action.cart.id,
                cartName: backupCart.name,
              },
            })
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap(() => of(EmptyShoppingCartGraphQLResult(true))),
          catchError((error) => {
            error.message =
              'error while processing ' + emptyShoppingCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(EmptyShoppingCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + emptyShoppingCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(EmptyShoppingCartGraphQLResult(false, error))
      })
    )
}

export const getShoppingCartPricesGraphQL = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetShoppingCartPricesGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.GetShoppingCartPricesGraphQL),
      switchMap((action: GetShoppingCartPricesGraphQLAction) => {
        return defer(async () => {
          const response = await apolloClient.query({
            query: gql`
              query getPricesForSingleShoppingCart($cartId: String!) {
                getPricesForSingleShoppingCart(cartId: $cartId) {
                  net
                  metal
                  shipping
                  vat
                  netSum
                  totalSum
                  currency
                  tecselect
                  cartItemPrices {
                    lineItemId
                    strikeThroughPrice
                    netPrice
                    currency
                    tecSelect
                    metalNeAddition
                  }
                }
              }
            `,
            variables: {
              cartId: action.cartId,
            },
          })
          const cartItemPrices = response.data.getPricesForSingleShoppingCart.cartItemPrices
          const cartPrices = {
            net: response.data.getPricesForSingleShoppingCart.net,
            netSum: response.data.getPricesForSingleShoppingCart.netSum,
            totalSum: response.data.getPricesForSingleShoppingCart.totalSum,
            currency: response.data.getPricesForSingleShoppingCart.currency,
            metal: response.data.getPricesForSingleShoppingCart.metal,
            vat: response.data.getPricesForSingleShoppingCart.vat,
            shipping: response.data.getPricesForSingleShoppingCart.shipping,
            tecselect: response.data.getPricesForSingleShoppingCart.tecselect,
          }
          await db.cartsv2prices.incrementalUpsert({
            cartId: action.cartId,
            prices: {
              shoppingCartItemPrices: cartItemPrices,
              shoppingCartPrices: cartPrices,
            },
          })

          if (action.onResult) {
            action.onResult()
          }
          await changeMetaData(db, 'cartsv2', { isUpdating: false }) // always switch back after prcices-update
        }).pipe(
          retry(1),
          switchMap(() => of(GetShoppingCartPricesGraphQLResult())),
          catchError((error) => {
            error.message =
              'error while processing ' + getShoppingCartPricesGraphQL.name + ' ' + error.message
            handleError(error)
            return of(GetShoppingCartPricesGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getShoppingCartPricesGraphQL.name + ' ' + error.message
        handleError(error)
        return of(GetShoppingCartPricesGraphQLResult())
      })
    )
}

export const resetShoppingCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<ResetShoppingCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.ResetShoppingCartGraphQL),
      concatMap((action: ResetShoppingCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation resetShoppingCart($input: ShoppingCartResetInput!) {
                resetShoppingCart(input: $input) {
                  updateResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cart.id,
              },
            },
          })

          return response.data
        }).pipe(
          retry(1),
          mergeMap(() => of(ResetShoppingCartGraphQLResult(true))),
          catchError((error) => {
            error.message =
              'error while processing ' + resetShoppingCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(ResetShoppingCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + resetShoppingCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(ResetShoppingCartGraphQLResult(false, error))
      })
    )
}

export const verifyCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<VerifyCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.VerifyCartGraphQL),
      switchMap((action: VerifyCartGraphQLAction) => {
        const obs = defer(async () => {
          const res = await apolloClient.query({
            query: gql`
              query verifyShoppingCart($input: ShoppingCartInput!) {
                verifyShoppingCart(input: $input) {
                  results {
                    errorCode
                    errorType
                    message
                    lineItemIds
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
              },
            },
          })

          // check & persist creditLimit
          const CreditLimitExceeded = res.data.verifyShoppingCart.results.find((result) => {
            return result.errorCode === 'CreditLimitExceeded'
          })
          const exceeded = CreditLimitExceeded !== undefined
          await db.upsertLocal('creditLimit', {
            exceeded: exceeded,
          })
          return res.data.verifyShoppingCart
        }).pipe(
          retry(1),
          switchMap((result) => of(VerifyCartGraphQLResult(result.results))),
          catchError((error) => {
            error.message =
              'error while processing ' + verifyCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(VerifyCartGraphQLResult([error]))
          })
        )
        if (action.pollInterval) {
          return timer(0, action.pollInterval).pipe(switchMap(() => obs))
        }
        return obs
      }),
      catchError((error) => {
        error.message = 'error in ' + verifyCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(VerifyCartGraphQLResult([error]))
      })
    )
}

export const createAddProductToCartGraphQLAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddProductToCartGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddProductToCartGraphQL),
      concatMap((action) => {
        return defer(async () => {
          const response = await apolloClient.mutate<
            {
              addShoppingCartItems: { updateResults: Omit<ShoppingCartUpdateResult, 'itemResults'> }
            },
            ShoppingCartAddItemsInput
          >({
            mutation: addItemsToCart,
            variables: {
              input: {
                cartId: action.payload.cartId,
                items: action.payload.items.map((payloadItem) => {
                  const discount = payloadItem.discount || { offerId: '', offerItemPosition: '' }
                  trackClick('add-to-cart', {
                    sapId: payloadItem.sapId,
                    amount: payloadItem.amount,
                    offerId: discount.offerId,
                    offerItemPosition: discount.offerItemPosition,
                  })
                  return { sapId: payloadItem.sapId, amount: payloadItem.amount, ...discount }
                }),
              },
            },
          })

          const results = response.data?.addShoppingCartItems.updateResults
          if (results?.success) {
            if (action.payload.context !== AddCartContextEnum.CartTemplate) {
              const id = `add-shopping-cart-items-${new Date().getTime()}`

              if (action.payload.items.length === 1) {
                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType: NotificationType.AddToCart,
                  id,
                  options: {
                    id,
                    title: action.payload.items[0].title,
                  },
                })
              } else {
                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType: NotificationType.AddToCartMultiple,
                  id,
                  options: {
                    id,
                    cartTitle: action.payload.cartTitle,
                    itemCount: action.payload.items.length,
                  },
                })
              }
            }
          }
          return addProductToCartGraphQLResult(results || null)
        }).pipe(
          retry(1),
          tap(() => {
            if (isMobile()) {
              Haptics.notification()
            }
          }),
          catchError((error) => {
            error.message =
              'error in ' + createAddProductToCartGraphQLAction.name + ' ' + error.message
            handleError(error)
            return of(addProductToCartGraphQLResult(null))
          })
        )
      })
    )
  }
}

export const addCartTemplatesToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddCartTemplatesToCartGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddCartTemplatesToCartGraphQL),
      concatMap((action) => {
        return defer(async () => {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addCartTemplatesToShoppingCart($input: ShoppingCartAddCartTemplateInput!) {
                addCartTemplatesToShoppingCart(input: $input) {
                  updateResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.payload.cartId,
                cartTemplateIds: action.payload.cartTemplateIds,
              },
            },
          })
          const results = response.data?.addCartTemplatesToShoppingCart?.updateResults

          const timestamp = new Date().getTime()
          const notificationId = `${action.payload.cartId}-${NotificationType.AddCartTemplateToCart}-${timestamp}`
          if (results?.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddCartTemplateToCart,
              id: notificationId,
              options: {
                cartName: action.payload.cartName ?? '',
                cartTemplateName: action.payload.cartTemplateName,
              },
            })
          }
          return addCartTemplatesToCartGraphQLResult(results || null)
        }).pipe(
          retry(1),
          catchError((error) => {
            error.message =
              'error in ' + addCartTemplatesToCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(addCartTemplatesToCartGraphQLResult(null))
          })
        )
      })
    )
  }
}

export const addCartTemplateItemsToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddCartTemplateItemsToCartGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddCartTemplateItemsToCartGraphQL),
      concatMap((action) => {
        return defer(async () => {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addCartTemplateItemsToCart($input: AddCartTemplateItemsToCartInput!) {
                addCartTemplateItemsToCart(input: $input) {
                  success
                  successItemCount
                  errorCode
                  errorMessage
                  itemResults {
                    success
                    errorCode
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
                cartTemplateId: action.cartTemplateId,
                exclude: action.exclude,
                include: action.include,
                search: action.search,
              },
            },
          })
          const results = response.data
            ?.addCartTemplateItemsToCart as AddCartTemplateItemsToCartOutput

          if (results?.success && results?.successItemCount === action.selectedItemCount) {
            const timestamp = new Date().getTime()
            const notificationId = `${action.cartId}-${NotificationType.AddCartTemplateItemsToCart}-${timestamp}`

            // Render success notification
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddCartTemplateItemsToCart,
              id: notificationId,
              options: {
                successItemCount: results?.successItemCount,
                cartName: action.cartName ?? '',
              },
            })
          } else {
            // Not all items were added
            let showNotificationForUnpurchaseableProducts = false

            const itemResults = results.itemResults

            if (itemResults) {
              // Determine which items failed to be added
              const unsuccessfulItemResults = itemResults.filter((itemResult) => {
                return !itemResult.success
              })

              // Check through the individual error messages to determine if there are unpurchaseable products
              unsuccessfulItemResults.map((failedItem) => {
                if (isUnpurchasableProductError(failedItem.errorCode)) {
                  showNotificationForUnpurchaseableProducts = true
                }
                return null
              })

              // Render notification warning of unpurchaseable products
              if (showNotificationForUnpurchaseableProducts) {
                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType:
                    NotificationType.AddCartTemplateItemsUnpurchaseableProductsToCartWarning,
                  id: `${action.cartId}-${NotificationType.AddCartTemplateItemsUnpurchaseableProductsToCartWarning}`,
                  options: {
                    body: '',
                    heading: '',
                  },
                })
              }
            }
          }
          return addCartTemplateItemsToCartGraphQLResult(
            results.success,
            results.successItemCount,
            results.errorCode,
            results.errorMessage
          )
        }).pipe(
          retry(1),
          mergeMap((results) =>
            of(
              addCartTemplateItemsToCartGraphQLResult(
                results.success,
                results.successItemCount,
                results.errorCode,
                results.errorMessage
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error in ' + addCartTemplateItemsToCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(
              addCartTemplateItemsToCartGraphQLResult(false, 0, error.errorMessage, error.message)
            )
          })
        )
      })
    )
  }
}

export const addOrderItemsToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<AddOrderItemsToCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddOrderItemsToCartGraphQL),
      concatMap((action: AddOrderItemsToCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddOrderItemsToCartMutation,
            AddOrderItemsToCartMutationVariables
          >({
            mutation: gql`
              mutation addOrderItemsToCart($input: AddOrderItemsToCartInput!) {
                addOrderToCart(input: $input) {
                  success
                  errorCode
                  errorMessage
                  itemResults {
                    success
                    errorCode
                  }
                  successItemCount
                }
              }
            `,
            variables: {
              input: {
                orderId: action.input.orderId,
                cartId: action.input.cartId,
                include: action.input.include,
                exclude: action.input.exclude,
                amount: action.input.amount,
                search: action.input.search,
              },
            },
          })

          if (
            response?.data?.addOrderToCart.success &&
            response?.data?.addOrderToCart.successItemCount === action.selectedItemCount
          ) {
            const timestamp = new Date().getTime()
            const notificationId = `${action.input.orderId}-${NotificationType.AddOrderItemsToCart}-${timestamp}`

            // CASE 1 Render success notification
            getEventSubscription().next({
              options: { successItemCount: response.data.addOrderToCart.successItemCount },
              type: EventType.Toast,
              notificationType: NotificationType.AddOrderItemsToCart,
              id: notificationId,
            })
          } else {
            // Not all items were added
            let showNotificationForUnpurchasableProducts = false
            let showNotificationForDiverseProducts = false

            if (response.data?.addOrderToCart.itemResults) {
              const itemResults = response.data?.addOrderToCart.itemResults

              // Determine which items failed to be added
              const unsuccessfulItemResults = itemResults.filter((itemResult) => {
                return !itemResult.success
              })

              // Check through the individual error messages to determine which notification to display
              unsuccessfulItemResults.map((failedItem) => {
                if (isCancelledOrCustomProductError(failedItem.errorCode)) {
                  showNotificationForDiverseProducts = true
                } else if (isUnpurchasableProductError(failedItem.errorCode)) {
                  showNotificationForUnpurchasableProducts = true
                }
                return null
              })
            }

            // CASE 2 Render notification warning of unpurchasable products
            if (showNotificationForUnpurchasableProducts) {
              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.OrderAddUnpurchasableArticlesToCartWarning,
                id: `${action.input.orderId}-${NotificationType.OrderAddUnpurchasableArticlesToCartWarning}`,
                options: {
                  body: '',
                  heading: '',
                },
              })
            }

            // CASE 3 Render notification warning of diverse products
            if (showNotificationForDiverseProducts) {
              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.OrderAddDiverseArticlesToCartWarning,
                id: `${action.input.orderId}-${NotificationType.OrderAddDiverseArticlesToCartWarning}`,
                options: {
                  body: '',
                  heading: '',
                },
              })
            }
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              AddOrderItemsToCartGraphQLResult(
                result?.addOrderToCart.success ?? false,
                '',
                '',
                action.input.orderId
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              AddOrderItemsToCartGraphQLResult.name +
              ' ' +
              error.message
            handleError(error)
            return of(AddOrderItemsToCartGraphQLResult(false, '', '', action.input.orderId))
          })
        )
      )
    )
}

export const addOrderItemsInArrearsToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<AddOrderItemsInArrearsToCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddOrderItemsInArrearsToCartGraphQL),
      concatMap((action: AddOrderItemsInArrearsToCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddOrderItemsInArrearsToCartMutation,
            AddOrderItemsInArrearsToCartMutationVariables
          >({
            mutation: gql`
              mutation addOrderItemsInArrearsToCart($input: AddOrderItemsInArrearsToCartInput!) {
                addOrderItemsInArrearsToCart(input: $input) {
                  success
                  errorCode
                  errorMessage
                  itemResults {
                    success
                    errorCode
                  }
                  successItemCount
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (
            response?.data?.addOrderItemsInArrearsToCart.success &&
            response?.data?.addOrderItemsInArrearsToCart.successItemCount ===
              action.selectedItemCount
          ) {
            const timestamp = new Date().getTime()
            const notificationId = `${action.input.cartId}-${NotificationType.AddOrderItemsToCart}-${timestamp}`

            // CASE 1 Render success notification
            getEventSubscription().next({
              options: {
                successItemCount: response.data.addOrderItemsInArrearsToCart.successItemCount,
              },
              type: EventType.Toast,
              notificationType: NotificationType.AddOrderItemsToCart,
              id: notificationId,
            })
          } else {
            // Not all items were added
            let showNotificationForUnpurchasableProducts = false
            let showNotificationForDiverseProducts = false

            if (response.data?.addOrderItemsInArrearsToCart.itemResults) {
              const itemResults = response.data?.addOrderItemsInArrearsToCart.itemResults

              // Determine which items failed to be added
              const unsuccessfulItemResults = itemResults.filter((itemResult) => {
                return !itemResult.success
              })

              // Check through the individual error messages to determine which notification to display
              unsuccessfulItemResults.map((failedItem) => {
                if (isCancelledOrCustomProductError(failedItem.errorCode)) {
                  showNotificationForDiverseProducts = true
                } else if (isUnpurchasableProductError(failedItem.errorCode)) {
                  showNotificationForUnpurchasableProducts = true
                }
                return null
              })
            }

            // CASE 2 Render notification warning of unpurchasable products
            if (showNotificationForUnpurchasableProducts) {
              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.OrderAddUnpurchasableArticlesToCartWarning,
                id: `${action.input.cartId}-${NotificationType.OrderAddUnpurchasableArticlesToCartWarning}`,
                options: {
                  body: '',
                  heading: '',
                },
              })
            }

            // CASE 3 Render notification warning of diverse products
            if (showNotificationForDiverseProducts) {
              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.OrderAddDiverseArticlesToCartWarning,
                id: `${action.input.cartId}-${NotificationType.OrderAddDiverseArticlesToCartWarning}`,
                options: {
                  body: '',
                  heading: '',
                },
              })
            }
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              AddOrderItemsInArrearsToCartGraphQLResult(
                result?.addOrderItemsInArrearsToCart.success ?? false,
                '',
                ''
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              AddOrderItemsInArrearsToCartGraphQLResult.name +
              ' ' +
              error.message
            handleError(error)
            return of(AddOrderItemsInArrearsToCartGraphQLResult(false, '', ''))
          })
        )
      )
    )
}
export const addOrReplaceOfferInCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<AddOrReplaceOfferInCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddOrReplaceOfferInCartGraphQL),
      concatMap((action: AddOrReplaceOfferInCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddOrReplaceOfferInCartMutation,
            AddOrReplaceOfferInCartMutationVariables
          >({
            mutation: gql`
              mutation addOrReplaceOfferInCart($input: AddOrReplaceOfferInCartInput!) {
                addOrReplaceOfferInCart(input: $input) {
                  success
                  errorCode
                  errorMessage
                  itemResults {
                    success
                    errorCode
                  }
                  successItemCount
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
                offerId: action.offerId,
                overwrite: action.overwrite,
                include: action.include,
                exclude: action.exclude,
                amount: action.amount,
                search: action.search,
              },
            },
          })

          if (
            response?.data?.addOrReplaceOfferInCart.success &&
            response?.data?.addOrReplaceOfferInCart.successItemCount === action.selectedItemCount
          ) {
            const timestamp = new Date().getTime()
            const notificationId = `${action.offerId}-${NotificationType.AddOrReplaceOfferInCart}-${timestamp}`

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddOrReplaceOfferInCart,
              id: notificationId,
              options: {
                offerName: action.offerName,
              },
            })
          } else {
            let showNotificationDiverseProducts = false
            let showNotificationUnpurchasebleProducts = false

            const itemResults = response.data?.addOrReplaceOfferInCart.itemResults

            if (itemResults) {
              // Determine which items failed to be added
              const failedItemResults = itemResults.filter((itemResult) => !itemResult.success)

              // Iterate through all failed items, break loop if all notifications are set to true
              for (const failedItem of failedItemResults) {
                if (isCancelledOrCustomProductError(failedItem.errorCode)) {
                  showNotificationDiverseProducts = true
                }
                if (isUnpurchasableProductError(failedItem.errorCode)) {
                  showNotificationUnpurchasebleProducts = true
                }

                if (showNotificationDiverseProducts && showNotificationUnpurchasebleProducts) {
                  break
                }
              }

              // Render notification warning of diverse products
              if (showNotificationDiverseProducts) {
                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType:
                    NotificationType.OfferAddDiverseProductsOrCancelledItemsToCartWarning,
                  id: `${action.offerId}-${NotificationType.OfferAddDiverseProductsOrCancelledItemsToCartWarning}`,
                  options: {
                    body: '',
                    heading: '',
                  },
                })
              }

              // Render notification warning of unpurchaseable
              if (showNotificationUnpurchasebleProducts) {
                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType: NotificationType.OfferAddUnpurchasableProductsToCartWarning,
                  id: `${action.offerId}-${NotificationType.OfferAddUnpurchasableProductsToCartWarning}`,
                  options: {
                    body: '',
                    heading: '',
                  },
                })
              }
            }
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              addOrReplaceOfferInCartGraphQLResult(result?.addOrReplaceOfferInCart.success ?? false)
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              addOrReplaceOfferInCartGraphQLEffect.name +
              ' ' +
              error.message
            handleError(error)
            return of(addOrReplaceOfferInCartGraphQLResult(false, error))
          })
        )
      )
    )
}

export const createAddMarkForNotification = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<AddMarkForNotificationAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddMarkForNotification),
      concatMap((action: AddMarkForNotificationAction) => {
        return defer(async () => {
          const col = db.markedcarts
          await col.insert({ id: action.cartId })
          return noop()
        })
      }),
      catchError((err) => {
        err.message = 'error in ' + createAddMarkForNotification.name + ' ' + err.message
        handleError(err)
        return of(noop())
      })
    )
  }
}

export const createRemoveMarkForNotification = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<RemoveMarkForNotificationAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.RemoveMarkForNotification),
      concatMap((action: RemoveMarkForNotificationAction) => {
        return defer(async () => {
          const col = db.markedcarts
          const markedCart = await col
            .findOne({
              selector: {
                id: action.cartId,
              },
            })
            .exec()
          if (markedCart) {
            await markedCart.remove()
          }
          return noop()
        })
      }),
      catchError((err) => {
        err.message = 'error in ' + createRemoveMarkForNotification.name + ' ' + err.message
        handleError(err)
        return of(noop())
      })
    )
  }
}

export const fetchAndReplaceCustomTitles = async (
  action: UpdateCustomProductsGraphQLAction,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  db: RxDatabase<CollectionsOfDatabase>
) => {
  const offerId = action.cart.offerId
  const requestItems = action.shoppingCartItems
    .filter((item) => item.offerItemPosition !== undefined && item.offerItemPosition !== null)
    .map(({ sapId, offerItemPosition }) => ({
      sapId,
      offerItemPosition,
    }))

  if (requestItems.length === 0) return

  const response = await apolloClient.query<UpdateCustomProductResponse>({
    query: OFFER_ITEM_TITLES,
    variables: {
      input: {
        offerId,
        offerItems: requestItems,
      },
    },
  })

  const cartToBackup = { ...action.cart }
  const responseItems = response.data.getOfferItemTitles
  if (responseItems.length > 0) {
    cartToBackup.items.forEach((backupCartItem) => {
      const matchingResponseItem = responseItems.find(({ sapId, offerItemPosition }) => {
        return (
          sapId === backupCartItem.sapId && offerItemPosition === backupCartItem.offerItemPosition
        )
      })
      if (!matchingResponseItem) return
      backupCartItem.product.title = matchingResponseItem.title
      backupCartItem.product.isCustomTitleReplaced = true
    })
    await db.cartsv2.upsert(cartToBackup)
  }
}

export const createUpdateCustomProductsGraphQLAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateCustomProductsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.UpdateCustomProductsGraphQL),
      switchMap((action: UpdateCustomProductsGraphQLAction) => {
        return defer(async () => {
          await changeMetaData(db, 'cartsv2', { isUpdatingCustomTitles: true })
          await fetchAndReplaceCustomTitles(action, apolloClient, db)
          await changeMetaData(db, 'cartsv2', { isUpdatingCustomTitles: false })
        }).pipe(
          retry(1),
          switchMap(() => of(UpdateShoppingCartPricesGraphQLResult())),
          catchError(async (error) => {
            error.message =
              'error while processing ' +
              createUpdateCustomProductsGraphQLAction.name +
              ' ' +
              error.message
            handleError(error)
            await changeMetaData(db, 'cartsv2', { isUpdatingCustomTitles: false })
            return of(UpdateShoppingCartPricesGraphQLResult())
          }),
          catchError((error) => {
            error.message =
              'error in ' + createUpdateCustomProductsGraphQLAction.name + ' ' + error.message
            handleError(error)
            return of(UpdateShoppingCartPricesGraphQLResult())
          })
        )
      })
    )
  }
}

async function getEans(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  action: GetVoltimumPointsGraphQLAction
) {
  const eanResponse = await apolloClient.query<GetProductsQuery, GetProductsQueryVariables>({
    query: gql`
      query getProducts($sapIds: [String!]!) {
        getProducts(sapIds: $sapIds) {
          eans
          sapId
        }
      }
    `,
    variables: {
      sapIds: action.items.map(({ sapId }) => sapId),
    },
  })

  const eanLookup: Array<{ ean: string; sapId: string }> = []
  eanResponse.data.getProducts.forEach((product) => {
    if (product.eans.length > 0) eanLookup.push({ sapId: product.sapId, ean: product.eans[0] })
  })
  return eanLookup
}

async function fetchVoltimumPoints(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  eanLookup: Array<{ ean: string; sapId: string }>,
  action: GetVoltimumPointsGraphQLAction
): Promise<Array<{ sapId: string; points: number }>> {
  const voltimumPointsResponse = await apolloClient.query<
    GetVoltimumPointsQuery,
    GetVoltimumPointsQueryVariables
  >({
    query: gql`
      query getVoltimumPoints($eans: [String!]!) {
        getVoltimumPoints(eans: $eans) {
          points
          multiplier
          ean
        }
      }
    `,
    variables: {
      eans: eanLookup.map(({ ean }) => ean),
    },
  })
  const sapIdsAndPoints: Array<{ sapId: string; points: number }> = []
  voltimumPointsResponse.data.getVoltimumPoints.forEach((pointsElement) => {
    const eanSapIdTuple = eanLookup.find((lookUpElement) => lookUpElement.ean === pointsElement.ean)
    if (!eanSapIdTuple) return
    const foundItem = action.items.find(({ sapId }) => sapId === eanSapIdTuple.sapId)
    if (!foundItem) return
    sapIdsAndPoints.push({
      sapId: foundItem.sapId,
      points: pointsElement.points * pointsElement.multiplier,
    })
  })
  return sapIdsAndPoints
}

export const getVoltimumPointsAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetVoltimumPointsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.GetVoltimumPointsGraphQL),
      switchMap((action: GetVoltimumPointsGraphQLAction) => {
        return defer(async () => {
          const eanLookup = await getEans(apolloClient, action)
          if (eanLookup.length === 0) return
          const sapIdsAndPoints = await fetchVoltimumPoints(apolloClient, eanLookup, action)
          action.onPointsCalculated(sapIdsAndPoints)
        }).pipe(
          retry(1),
          switchMap(() => of(GetVoltimumPointsGraphQLResult())),
          catchError((error) => {
            error.message =
              'error while processing ' + getVoltimumPointsAction.name + ' ' + error.message
            handleError(error)
            action.onPointsCalculated([])
            return of(GetVoltimumPointsGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getVoltimumPointsAction.name + ' ' + error.message
        handleError(error)
        return of(GetVoltimumPointsGraphQLResult())
      })
    )
  }
}

export const getIdsFormFieldsEffect = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<GetIdsFormFieldsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.GetIdsFormFieldsGraphQL),
      switchMap((action: GetIdsFormFieldsGraphQLAction) =>
        defer(async () => {
          const res = await apolloClient.query({
            query: gql`
              query getIdsShoppingCartFormFields($cartId: String!) {
                getIdsShoppingCartFormFields(cartId: $cartId) {
                  success
                  errorCode
                  errorMessage
                  idsFormFields {
                    version
                    action
                    warenkorb
                  }
                }
              }
            `,
            variables: {
              cartId: action.cartId,
            },
          })
          return res.data.getIdsShoppingCartFormFields
        }).pipe(retry(1))
      ),
      map((result: IdsFormFieldsResult) => getIdsFormFieldsResult(result)),
      catchError((error) => {
        error.message = 'error in ' + getIdsFormFieldsEffect.name + ' ' + error.message
        handleError(error)
        return of(getIdsFormFieldsResult(error))
      })
    )
  }
}

export const getOciFormFieldsEffect = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<GetOciFormFieldsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.GetOciFormFieldsGraphQL),
      switchMap((action: GetOciFormFieldsGraphQLAction) =>
        defer(async () => {
          const res = await apolloClient.query({
            query: gql`
              query getOciFormFieldsForCart($cartId: String!) {
                getOciFormFieldsForCart(cartId: $cartId) {
                  success
                  errorMessage
                  formFields {
                    name
                    value
                  }
                }
              }
            `,
            variables: {
              cartId: action.cartId,
            },
          })
          return res.data.getOciFormFieldsForCart
        }).pipe(retry(1))
      ),
      map((result: OciCartFormFieldsResponse) => getOciFormFieldsResult(result)),
      catchError((error) => {
        error.message = 'error in ' + getOciFormFieldsEffect.name + ' ' + error.message
        handleError(error)
        return of(getOciFormFieldsResult(error))
      })
    )
  }
}

export const addToCartFromIdsXmlEffect = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (action$: Observable<AddToCartFromIdsXmlAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddToCartFromIdsXml),
      concatMap((action: AddToCartFromIdsXmlAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddToCartFromIdsXmlMutation,
            AddToCartFromIdsXmlMutationVariables
          >({
            mutation: gql`
              mutation addToCartFromIdsXml($input: AddToCartFromIdsXmlInput!) {
                addToCartFromIdsXml(input: $input) {
                  success
                  errorMessage
                  itemResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
                xmlCart: action.xmlCart,
              },
            },
          })
          return response?.data?.addToCartFromIdsXml
        }).pipe(
          retry(1),
          catchError((error) => {
            error.message =
              'error while processing ' + addToCartFromIdsXmlEffect.name + ' ' + error.message
            handleError(error)
            return of(
              addToCartFromIdsXmlResult({
                success: false,
                errorMessage: error.message,
                errorCode: '',
                itemResults: [],
              })
            )
          })
        )
      ),
      map((result: ShoppingCartUpdateResultFromSchema) => addToCartFromIdsXmlResult(result)),
      catchError((error) => {
        error.message =
          'error while processing ' + addToCartFromIdsXmlEffect.name + ' ' + error.message
        handleError(error)
        return of(
          addToCartFromIdsXmlResult({
            success: false,
            errorMessage: error.message,
            errorCode: '',
            itemResults: [],
          })
        )
      })
    )
}

/**
 * This fetches the shopping carts from a user and inserts them in to RxDB cartsv2.
 * This is only required for special cases, as in other cases the feed and the feed updates take care of the cartsv2 collection.
 * @param db
 * @param apolloClient
 */
export const manuallyUpdateCartsEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<ManuallyUpdateCartsActions>) =>
    action$.pipe(
      ofType(CartsActionTypes.ManuallyUpdateCarts),
      switchMap(() => {
        return defer(async () => {
          const query = GET_SHOPPING_CARTS
          const response = await apolloClient.query<
            GetShoppingCartsQuery,
            GetShoppingCartsQueryVariables
          >({
            query,
          })
          if (response?.data.getShoppingCarts) {
            const carts = response.data.getShoppingCarts
            await db.cartsv2.bulkUpsert(carts)
            return manuallyUpdateCartsResult(carts)
          }
          return manuallyUpdateCartsResult([])
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(manuallyUpdateCartsResult([]))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(manuallyUpdateCartsResult([]))
      })
    )
}

export const initAllCartEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    addOrReplaceOfferInCartGraphQLEffect(apolloClient),
    addOrderItemsToCartGraphQLEffect(apolloClient),
    addOrderItemsInArrearsToCartGraphQLEffect(apolloClient),
    createAddMarkForNotification(db),
    createRemoveMarkForNotification(db),
    createUpdateCartGraphQLEffect(db, apolloClient),
    deleteCartItemsGraphQLEffect(db, apolloClient),
    emptyShoppingCartGraphQLEffect(db, apolloClient),
    getShoppingCartPricesGraphQL(db, apolloClient),
    resetShoppingCartGraphQLEffect(db, apolloClient),
    moveCartItemsGraphQLEffect(db, apolloClient),
    notifyCartGraphQLEffect(db, apolloClient),
    submitCartGraphQLEffect(db, apolloClient),
    createChangeProductAmountEffect(db, apolloClient),
    createAddProductToCartGraphQLAction(db, apolloClient),
    verifyCartGraphQLEffect(db, apolloClient),
    addCartTemplatesToCartGraphQLEffect(apolloClient),
    addCartTemplateItemsToCartGraphQLEffect(apolloClient),
    createUpdateCustomProductsGraphQLAction(db, apolloClient),
    getVoltimumPointsAction(db, apolloClient),
    getIdsFormFieldsEffect(apolloClient),
    getOciFormFieldsEffect(apolloClient),
    verifyCartInOfferGraphQLEffect(apolloClient),
    addToCartFromIdsXmlEffect(apolloClient),
    manuallyUpdateCartsEffect(db, apolloClient),
  ]
}
