import { useState, useEffect, useRef, useCallback } from 'react'
import { ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'
import { gql, useApolloClient } from '@apollo/client'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import { useRxDB } from 'rxdb-hooks'
import short from 'short-uuid'
import { BehaviorSubject } from 'rxjs'
import { useUserDataV2 } from './useUserDataV2'
import { priceStockSearchQuery } from '../queries/priceStockSearchQuery'
import {
  GetPriceAndStockDataInSearchQueryQuery,
  GetPriceAndStockDataInSearchQueryQueryVariables,
  ProductSearchUserData,
  SearchProductsQuery,
  SearchProductsQueryVariables,
} from '@obeta/schema'
import {
  CompleteSearchProduct,
  IncompleteSearchProduct,
  SearchProductWithPriceAndStocks,
} from '@obeta/models/lib/schema-models'
import { useWarehouseContext } from '../stores/useWarehouseContext'

export const searchQuery = gql`
  query searchProducts(
    $searchString: String
    $hitsPerPage: Int
    $filter: String
    $page: Int
    $ruleContexts: String
  ) {
    searchProducts(
      searchString: $searchString
      requestOptions: {
        facets: [
          "type"
          "warehouseAvailability.warehouse_10"
          "warehouseAvailability.warehouse_500"
          "supplierFilterName"
        ]
        hitsPerPage: $hitsPerPage
        filters: $filter
        page: $page
        ruleContexts: $ruleContexts
      }
    ) {
      nbHits
      nbPages
      facets {
        name
        values {
          value
          count
          meta
        }
        meta
      }
      oxomiToken
      oxomiPortal
      products {
        title
        sapId
        replacementArticleSapId
        replacementProducts {
          dehaId
        }
        articleDescription
        imageData {
          sapId
          images {
            large
          }
        }
        supplierImageData {
          large
        }
        isDeleted
        isForSale
        isSendable
        isCutProduct
        isDiscontinued
        priceDimension
        minimumAmount
        oxomiId
        obetaId
        dehaId
        unit
        type
        supplierId
        isTopseller
      }
      queryId
      userData {
        promotion {
          actionName
          target
          images {
            desktop
            mobile
            tabletSmall
            tabletWide
          }
        }
      }
    }
  }
`

export interface SearchRequest {
  itemsPerPage: number
  page: number
  searchParams?: ArticleSearchParamsWithId
}

type DataUpdatedParams = {
  prevEntities: IncompleteSearchProduct[]
  nextEntities: IncompleteSearchProduct[]
} | null

export interface SearchResult {
  loading: boolean
  hits: number
  maxPage: number
  searchResult: Array<IncompleteSearchProduct>
  fetchMore: (request: SearchRequest) => Promise<void>
  dataUpdated$: BehaviorSubject<DataUpdatedParams>
  queryId: string
  bannerData: Array<ProductSearchUserData>
}

interface SearchMeta {
  currentRequests: Set<string>
  loadedPages: Set<number>
  currentSearchParamsId: string | null
  itemsPerPage: number
  currentWareHouse: string | null
}

const fillArray = <T,>(p: { base: T[]; incoming: T[]; fromIndex: number }) => {
  const { base, incoming, fromIndex } = p

  const cpyBase = [...base] // make sure helper stays pure
  for (let i = 0; i < incoming.length; i += 1) {
    cpyBase[i + fromIndex] = incoming[i]
  }

  return cpyBase
}

const dataUpdated$ = new BehaviorSubject<DataUpdatedParams>(null)

/**
 * using searchParams request and build available etimClasses (classes of features product can have),
 *  etimAttributes (features product can have) and ...
 * @param searchParams
 * @returns
 */
export const useArticleSearch = (
  request: SearchRequest,
  onItemsPerPageChange?: () => void
): SearchResult => {
  const [searchResult, setSearchResult] = useState<Array<IncompleteSearchProduct>>([])
  const prevSearchResultRev = useRef(searchResult)
  const prevItemsPerPage = useRef(request.itemsPerPage)

  const [hits, setHits] = useState(-1)

  const [maxPage, setMaxPage] = useState(-1)
  const [bannerData, setBannerData] = useState<Array<ProductSearchUserData>>([])
  const [loading, setLoading] = useState(false)
  const { isLoggedIn } = useUserDataV2()
  const [queryId, setQueryId] = useState('')
  const { warehouseId } = useWarehouseContext()

  const metaRef = useRef<SearchMeta>({
    currentRequests: new Set(),
    loadedPages: new Set(),
    currentSearchParamsId: null,
    itemsPerPage: request.itemsPerPage,
    currentWareHouse: warehouseId,
  })

  const client = useApolloClient()
  const db = useRxDB()

  useEffect(() => {
    dataUpdated$.next({ prevEntities: prevSearchResultRev.current, nextEntities: searchResult })
    prevSearchResultRev.current = searchResult
  }, [searchResult])

  const fetchMore = useCallback(
    async (request: SearchRequest) => {
      if (!request.searchParams) {
        return
      }

      const reset =
        metaRef.current.currentSearchParamsId !== request.searchParams.id ||
        metaRef.current.itemsPerPage !== request.itemsPerPage ||
        metaRef.current.currentWareHouse !== warehouseId

      if (reset) {
        metaRef.current.currentSearchParamsId = request.searchParams.id
        metaRef.current.itemsPerPage = request.itemsPerPage
        metaRef.current.currentRequests = new Set() // invalidate all current requests
        metaRef.current.loadedPages = new Set()
        metaRef.current.currentWareHouse = warehouseId
      } else if (metaRef.current.loadedPages.has(request.page)) {
        return // terminate if page was cached already.
      }

      const reqId = short.generate()
      metaRef.current.currentRequests.add(reqId)

      setLoading(true)

      const didItemsPerPageChange = prevItemsPerPage.current !== request.itemsPerPage

      const res = await client.query<SearchProductsQuery, SearchProductsQueryVariables>({
        query: searchQuery,
        variables: {
          searchString: request.searchParams.searchString,
          hitsPerPage: request.itemsPerPage,
          filter: buildFilterParams(request.searchParams, warehouseId),
          page: didItemsPerPageChange ? 0 : request.page,
          ruleContexts: request.searchParams.trigger ? request.searchParams.trigger : 'nosearchbar', // algolia's ruleContexts is used to see if a request was trigger from within searchbar or not
        },
        fetchPolicy: 'no-cache',
      })

      if (didItemsPerPageChange && onItemsPerPageChange) {
        onItemsPerPageChange()
      }

      prevItemsPerPage.current = request.itemsPerPage
      const response = res.data.searchProducts
      const indexBase = request.page * request.itemsPerPage

      if (!metaRef.current.currentRequests.has(reqId)) {
        // current request was invalidated.
        return
      }

      if (reset) {
        setSearchResult(() => {
          return fillArray({ base: [], incoming: response.products, fromIndex: indexBase })
        })
      } else {
        setSearchResult((curr) => {
          return fillArray<IncompleteSearchProduct>({
            base: curr,
            incoming: response.products,
            fromIndex: indexBase,
          })
        })
      }

      delete metaRef.current.currentRequests[reqId]
      metaRef.current.loadedPages.add(request.page)

      // selected filter hints
      setHits(response.nbHits)

      setMaxPage(response.nbPages)
      setQueryId(response.queryId)
      setBannerData(response.userData)

      if (response) {
        setLoading(false)
      }

      // fire and forget, do not await result
      db.upsertLocal('oxomi', {
        token: response.oxomiToken,
        portal: response.oxomiPortal,
      })

      const userv2 = await db.getLocal<UserV2>('userv2')

      // decouple prices and stock from processing of articles
      const userStoreId = userv2?.get('settings')?.defaultStoreId
      if (!userStoreId) {
        // Sentry.captureException(new Error('user store id is undefined')) // TODO replace former Sentry-Code with DataDog
      }
      const sapIds = response.products.map((prod) => prod.sapId)
      if (sapIds.length > 0 && isLoggedIn) {
        try {
          const results = await client.query<
            GetPriceAndStockDataInSearchQueryQuery,
            GetPriceAndStockDataInSearchQueryQueryVariables
          >({
            query: priceStockSearchQuery,
            variables: {
              sapIds,
              warehouseIds: [userStoreId, warehouseId].filter(Boolean),
            },
          })

          const productData: SearchProductWithPriceAndStocks[] = results.data.getProducts
          setSearchResult((current) => {
            productData.forEach((data) => {
              const el = current.find((p) => p?.sapId === data?.sapId)
              if (el) {
                // Add stock and prices to transform IncompleteProduct to a CompleteProduct
                ;(el as CompleteSearchProduct).stock = data.stock
                ;(el as CompleteSearchProduct).prices = data.prices
                ;(el as CompleteSearchProduct).stockAvailabilityEstimate =
                  data.stockAvailabilityEstimate
              }
            })
            return [...current]
          })
        } catch (err) {
          // Sentry.captureException(err) // TODO replace former Sentry-Code with DataDog
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, db, isLoggedIn, warehouseId]
  )

  /**
   * TODO: queue: new request waits until previous one is finished ?
   */
  useEffect(() => {
    fetchMore(request)
  }, [fetchMore, request])

  return {
    searchResult,
    loading,
    hits,
    maxPage,
    fetchMore,
    dataUpdated$,
    queryId,
    bannerData,
  }
}
