import { ProductAggregate } from '../Article/Shop/Product'
import { Category } from '../Category'

interface Meta {
  name: string
  order?: number
  type?: string
  unit?: string
}

export interface FacetElement {
  value: string
  count: number
  meta: Meta
}
export interface Facet {
  name: string
  meta: Meta
  values: FacetElement[]
}
export interface FacetsStats {
  name: string
  min: number
  max: number
}

export interface HierarchicalFacetElement extends FacetElement {
  parentFacet?: FacetElement
  subFacets: FacetElement[]
}

export interface SearchResponse {
  nbHits: number
  nbPages: number
  facets: Facet[]
  facetsStats: FacetsStats[]
  oxomiPortal: string
  oxomiToken: string
  products: ProductAggregate[]
  queryId: string
}

export enum ArticleSearchArea {
  ALL = '0',
  IMMEDIATELY_AVAILABLE = '5',
}

export const ArticleSearchAreaLabelsByKey: Record<ArticleSearchArea, string> = {
  [ArticleSearchArea.ALL]: 'ALL',
  [ArticleSearchArea.IMMEDIATELY_AVAILABLE]: 'IMMEDIATELY_AVAILABLE',
}

export interface Etim {
  id: string
  name: string
  count?: number
}

export interface ArticleSearchSupplier {
  id: string
  name: string
}

export interface SelectedEtimValue {
  facet?: Facet
  value: string | number
  count?: number
}

type sapId = string
type dehaId = string

export interface ArticleSearchParams {
  searchString?: string
  /**
   * @deprecated this mixes different facets and aspects together and is therefore hard to understand
   */
  articleSearchArea?: ArticleSearchArea
  obetaCategory?: Category
  dehaCategory?: Category
  suppliers?: Record<string, ArticleSearchSupplier> | null
  selectedEtim?: Etim
  etim?: SelectedEtimValue[]
  sendable?: boolean
  immediatelyAvailable?: boolean
  productIds?: sapId[]
  productDehaIds?: dehaId[]
  minListPrice?: number
  maxListPrice?: number
  customTitle?: string
  productDehaIdsFilterLabel?: string
  fundings?: string[]
  trigger?: string
}

export interface ArticleSearchParamsWithId extends ArticleSearchParams {
  id: string
}
