import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { ARTICLE_DETAILS } from '@obeta/data/lib/queries/articleDetails'
import { getCurrentProductIds } from '@obeta/data/lib/hooks/useCurrentProductIds'
import { getImgProxyUrlServerSide } from '@obeta/utils/lib/ensureValidImgProxyUrl'

const client = new ApolloClient({
  ssrMode: true,
  link: new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_HOST as string,
  }),
  cache: new InMemoryCache(),
})

const fetchArticleDataServerSide = async (articleId: string) => {
  try {
    const { data } = await client.query({
      query: ARTICLE_DETAILS,
      variables: getCurrentProductIds(articleId),
    })

    return data?.getProducts?.[0]
  } catch (error) {
    return null
  }
}

export const getArticleSeoData = async (articleId: string) => {
  const product = await fetchArticleDataServerSide(articleId)

  if (!product) return {}

  const productImageUrl =
    product.images?.length > 0 ? getImgProxyUrlServerSide(product.images[0].url) : ''
  const supplierImageUrl = getImgProxyUrlServerSide(product.supplierImageData?.large) || ''

  const imageUrl = productImageUrl || supplierImageUrl

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: product.title,
    description: product.articleDescription,
    image: imageUrl,
  }

  return {
    metaInfo: [
      { property: 'og:title', content: product.title },
      { property: 'og:type', content: 'article' },
      { property: 'og:description', content: product.articleDescription },
      { property: 'og:productId', content: product.dehaId },
      { property: 'og:image', content: imageUrl },

      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: product.title },
      { name: 'twitter:description', content: product.articleDescription },
      { name: 'twitter:image', content: imageUrl },
    ],
    structuredData,
  }
}
