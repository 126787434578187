import React from 'react'
import styles from './CheckoutStoreCard.module.scss'
import { Box, Typography, Card, CardContent, Radio, FormControlLabel } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ReactComponent as CollectBoxIcon } from 'assets/icon/designsystem/abholbox.svg'
import { ReactComponent as MobileCollectStoreIcon } from 'assets/icon/designsystem/mobiler_abholstandort.svg'
import { StoreV2, StoreV2Types } from '@obeta/models/lib/models/Stores/StoreV2'
import clsx from 'clsx'
import { formatOpeningHours } from '@obeta/utils/lib/data-formatter/formatOpeningHours'

interface Props {
  storeV2: StoreV2
}

export const CheckoutStoreCard: React.FC<Props> = (props) => {
  const { storeV2 } = props

  const openingHours = formatOpeningHours(storeV2.openingHours)

  return (
    <Card className={styles.card} elevation={0}>
      <CardContent className={styles.cardContent}>
        <Grid container direction="row">
          <Grid size={{ xs: 12 }}>
            <Box className={styles.headerBox}>
              <span>
                {storeV2.type === StoreV2Types.renzbox && (
                  <FormControlLabel
                    className={clsx(styles.formControlLabel, styles.halfLineHeight)}
                    value={storeV2.id}
                    control={<Radio />}
                    label={<CollectBoxIcon />}
                  />
                )}

                {storeV2.type === StoreV2Types.pickup && (
                  <FormControlLabel
                    className={clsx(styles.formControlLabel, styles.halfLineHeight)}
                    value={storeV2.id}
                    control={<Radio />}
                    label={<MobileCollectStoreIcon />}
                  />
                )}

                {storeV2.type === StoreV2Types.normal && (
                  <FormControlLabel
                    className={styles.formControlLabel}
                    value={storeV2.id}
                    control={<Radio />}
                    label={<Typography variant={'bodyBold'}>{storeV2.address.name1}</Typography>}
                  />
                )}
              </span>
              {storeV2.distance !== 0 && (
                <Typography variant={'smallText'}>{storeV2.distance} km</Typography>
              )}
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {storeV2.type === StoreV2Types.renzbox && (
              <Box className={styles.renzBox}>
                <Typography variant={'bodyBold'} className={styles.halfPaddingTop}>
                  {storeV2.address.name1}
                </Typography>
                <br />

                <Typography variant={'body'}>
                  {storeV2.address?.street + ' ' + storeV2.address?.houseNumber}
                </Typography>
                <Typography variant={'body'}>
                  {storeV2.address?.zipCode + ' ' + storeV2.address?.city}
                </Typography>
              </Box>
            )}

            {storeV2.type !== StoreV2Types.renzbox && (
              <Box className={styles.contentBox}>
                <Typography variant={'body'}>
                  {storeV2.address?.street + ' ' + storeV2.address?.houseNumber}
                </Typography>
                <Typography variant={'body'}>
                  {storeV2.address?.zipCode + ' ' + storeV2.address?.city}
                </Typography>
                <br />
                <div className={styles.flexRow}>
                  <div className={styles.days}>
                    {openingHours.map((openingHourGrouping) => {
                      return (
                        <Typography variant="body" key={openingHourGrouping.days}>
                          {openingHourGrouping.days}
                        </Typography>
                      )
                    })}
                  </div>
                  <div className={styles.times}>
                    {openingHours.map((openingHourGrouping) => {
                      return (
                        <Typography variant="body" key={openingHourGrouping.times}>
                          {openingHourGrouping.times}
                        </Typography>
                      )
                    })}
                  </div>
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
