import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { Autocomplete, TextField, Theme, useMediaQuery } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ISearchInputProps } from './types'
import { ReactComponent as BarcodeScanner } from 'assets/icon/designsystem/scan.svg'
import styles from './SearchInput.module.scss'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useLocation } from '@obeta/data/lib/hooks/useHistoryApi'
import { SuggestionOption, SuggestionWrapper } from './SuggestionItem'
import {
  ESuggestionGroup,
  SuggestionItem,
  useSearchDropdownData,
} from '@obeta/data/lib/hooks/useSearchDropdownData'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
import coreVariables from '@obeta/assets/theme/coreVariablesV2.module.scss'
import { useTranslation } from 'react-i18next'
import { Category } from '@obeta/models/lib/models/Category'
import { useSearchArticles } from '@obeta/data/lib/hooks/useSearchArticles'
import { useBarcodeScanner } from '@obeta/data/lib/hooks/useBarcodeScanner'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'

export const SearchInput: React.FC<ISearchInputProps> = (props) => {
  const { showBarCodeScanner = false, ...rest } = props
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.between('xs', 'md'))
  const { t } = useTranslation()
  const location = useLocation()

  const [showSearchModal, setShowSearchModal] = useState(false)
  const close = useCallback(() => setShowSearchModal(false), [])
  const show = useCallback(() => setShowSearchModal(true), [])
  const { startSearchScan } = useBarcodeScanner()
  const { changeSearchParams, searchParams } = useArticlesSearchProvider()

  const alterSearchParamsAndClose = useCallback(
    (
      paramName: 'searchString' | 'obetaCategory',
      paramValue: string | Category,
      cleanSearch = false
    ) => {
      let newSearchParams = {}
      if (!cleanSearch && searchParams) {
        newSearchParams = Object.assign(newSearchParams, searchParams)
      }
      newSearchParams[paramName] = paramValue

      const replaceRoute = location.pathname.startsWith('/search')
      changeSearchParams(
        {
          searchParams: newSearchParams,
          route: replaceRoute ? 'replace' : 'push',
        },
        'searchbar'
      )
      close()
    },
    [changeSearchParams, close, location.pathname, searchParams]
  )

  /**
   * Always start new search when searchString has changed
   */
  const onSearchConfirmed = useCallback(
    (term) => alterSearchParamsAndClose('searchString', term, true),
    [alterSearchParamsAndClose]
  )

  const searchArticlesProps = useSearchArticles(searchParams, onSearchConfirmed)

  const [highlightedOption, setHighlighted] = useState<SuggestionItem | null>(null)
  const suggestions = useSearchDropdownData(searchArticlesProps.searchText)
  const [searchInputValue, setSearchInputValue] = useState<SuggestionItem>({
    title: searchArticlesProps.searchText,
    group: ESuggestionGroup.Suggestion,
  })

  const mobileHeaderHeight = useRemToPx(parseFloat(coreVariables.mobileHeaderHeight))

  const isTopSectionVisible = useMobileScrollHandler({
    targetSectionHeight: mobileHeaderHeight,
    isEnabled: isMobile,
  })

  const keyboardHeight = useKeyboardHeight()
  const {
    dimensions: { height: headerHeight },
  } = useHeaderDimensions()

  const headerElement = document.getElementById('app-header')

  const handleSearchDropdownOpen = () => show()
  const handleSearchDropdownClose = () => {
    close()
    setHighlighted(null)
  }

  const handleAutocompleteChange = (event, value, reason) => {
    if (reason === 'selectOption') {
      if (value?.type === 'category') {
        alterSearchParamsAndClose(
          'obetaCategory',
          { name: value.title, id: value.id, level: 0, rootId: value.id },
          true
        )
        handleSearchDropdownClose()
      } else {
        searchArticlesProps.handleSetSearchValue(value?.title || '')
        handleSubmit()
      }
      setSearchInputValue(value)
    }
    if (reason === 'createOption') {
      searchArticlesProps.handleSetSearchValue(value)
      handleSubmit()
    }
  }

  const handleHighlight = (event, option, reason) => {
    if (reason === 'keyboard') {
      setHighlighted(option)
    }
  }

  const handleInputChange = (event, value, reason) => {
    if (reason === 'reset' && !value) {
      return
    }
    searchArticlesProps.handleSetSearchValue(value)
  }

  const handleBarCodeClick = (e) => {
    e.stopPropagation()
    startSearchScan()
  }

  const handleSubmit = () => {
    searchArticlesProps.handleSubmit()
    handleSearchDropdownClose()
  }

  const mobilePopperStyles = clsx(styles['autocomplete-popper-mobile'], {
    [styles['autocomplete-popper-mobile--scrolled']]: !isTopSectionVisible,
  })
  const autocompleteClasses = {
    root: styles['autocomplete-root'],
    input: styles['autocomplete-input'],
    listbox: styles['autocomplete-listbox'],
    option: styles['search-suggestion-item'],
    paper: styles['search-suggestion-paper'],
    popper: isMobile ? mobilePopperStyles : styles['autocomplete-popper'],
  }

  const headerWithSafeAreaHeight = `calc(${headerHeight}px + var(--obeta-safe-area-top-root))`
  const listboxProps = {
    style: {
      maxHeight: `calc(100vh - ${headerWithSafeAreaHeight} - ${keyboardHeight}px )`,
    },
  }
  const popperProps = isMobile ? { anchorEl: headerElement } : undefined

  const showEndAdornment = showBarCodeScanner
  const endAdornment = showEndAdornment && (
    <div className={styles['scanner-icon']} onClick={handleBarCodeClick}>
      <BarcodeScanner width={24} height={24} />
    </div>
  )

  const SearchIconComponent = (
    <div className={styles['search-icon-wrapper']} onClick={() => handleSubmit()}>
      <SearchIcon className={styles['search-icon']} />
    </div>
  )

  const renderInput = (params) => (
    <div id="searchInputWrapper" className={styles['search-input-wrapper']}>
      <TextField
        {...params}
        {...rest}
        classes={{
          root: clsx(styles['input-root'], { [styles.withAdornment]: showEndAdornment }),
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment,
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          },
        }}
      />

      {SearchIconComponent}
    </div>
  )

  const renderGroup = (group) => (
    <SuggestionWrapper key={`${group.group}-${group.key}`} group={group} />
  )

  const renderOption = (props, option, { inputValue }) => {
    const isSelected =
      option.title === highlightedOption?.title && option.type === highlightedOption?.type
    return (
      <SuggestionOption
        key={`${option.title}-${option.type}`}
        inputValue={inputValue}
        option={option}
        optionProps={props}
        selected={isSelected}
      />
    )
  }

  return (
    <div className={clsx(styles['flex-column-wrapper'], styles['search-container'])}>
      <Autocomplete
        id="search-suggestions"
        classes={autocompleteClasses}
        sx={{ height: '100%', width: '100%' }}
        options={suggestions}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : option.title
        }}
        renderGroup={renderGroup}
        renderInput={renderInput}
        renderOption={renderOption}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        onOpen={handleSearchDropdownOpen}
        onClose={handleSearchDropdownClose}
        onHighlightChange={handleHighlight}
        value={searchInputValue}
        inputValue={searchArticlesProps.searchText}
        open={showSearchModal}
        forcePopupIcon={false}
        noOptionsText={t('HEADER.NO_RESULT')}
        blurOnSelect
        freeSolo={true}
        ListboxProps={listboxProps}
        componentsProps={{ popper: popperProps }}
      />
    </div>
  )
}
