import React from 'react'
import { Typography } from '@mui/material'
import styles from './ProfileDropdownHeader.module.scss'
import { useUserAddressesV2 } from '@obeta/data/lib/hooks/useUserAddressesV2'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { UserType } from '@obeta/models/lib/models/Users/UserV2'

interface IProfileDropdownHeaderProps {
  /**
   * @default false
   */
  isMultiAccount?: boolean
}

export const ProfileDropdownHeader: React.FC<IProfileDropdownHeaderProps> = (props) => {
  const { isMultiAccount } = props
  const { user } = useUserDataV2()
  const { mainAddress } = useUserAddressesV2()

  const getBottomSection = () => {
    if (!isMultiAccount) {
      return <Typography variant="body">Konto {user?.customerNumber}</Typography>
    }
    //TODO change mock to real dropdown
    return <div>Dropdown</div>
  }

  return (
    <div className={styles.headerWrapper}>
      <Typography variant="bodyBold">
        Hallo,{' '}
        {user?.type === UserType.main
          ? mainAddress?.address.name1
          : user?.settings?.name.substring(user.settings.name.indexOf(' '))}
        !
      </Typography>
      {getBottomSection()}
    </div>
  )
}
