import { IMGPROXY_URL } from './config'

const CLASSIC_SHOP_HOST = 'classic.obeta.de'

export const ensureValidImgProxyUrl = (url?: string) => {
  if (!url) return undefined
  if (url.includes(CLASSIC_SHOP_HOST)) return undefined
  return url
}

export const getImgProxyUrlServerSide = (url: string) => {
  if (!ensureValidImgProxyUrl(url)) return url

  const path = `/plain/${url}`

  // The optimal size for sharing links on Facebook, Twitter, and LinkedIn is 1200px x 630px
  const defaultImgWidth = 1200
  const defaultImgHeight = 630

  return `${IMGPROXY_URL}resizing_type:fill/rs:fill:${defaultImgWidth}:${defaultImgHeight}${path}`
}
