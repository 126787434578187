import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'
import { Offer } from '@obeta/schema'

interface ICheckpoint {
  since?: string
  lastId?: string
}

const emptyCheckpoint: ICheckpoint = {
  since: undefined,
  lastId: undefined,
}

const createCheckpoint = (doc: Offer): ICheckpoint => ({
  lastId: doc.id,
  since: doc.updatedAt,
})

export const offersv2Entity: EntityDescriptor<Omit<Offer, '__typename' | 'deleted'>> = {
  name: 'offersv2',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'offersv2',
    version: 2,
    description: 'a document representing an offer in the new shop',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      offerName: {
        type: 'string',
      },
      validFrom: {
        type: 'string',
      },
      validTo: {
        type: 'string',
      },
      locked: {
        type: 'boolean',
      },
      active: {
        type: 'boolean',
      },
      cancelled: {
        type: 'boolean',
      },
      updatedAt: {
        type: 'string',
      },
    },
  },
  subscription: null,
  pullSync: {
    batchSize: 5000,
    queryBuilder: (checkpoint: ICheckpoint, limit) => {
      let filterDeleted = false
      if (!checkpoint) {
        // the first pull does not have a start-document and does not include deleted offers
        checkpoint = emptyCheckpoint
        filterDeleted = true
      }
      const query = `query getOffers ($since: String, $lastId: String, $batchSize: Float, $filterDeleted: Boolean){
        getOffers(since:$since, lastId: $lastId, batchSize:$batchSize, filterDeleted: $filterDeleted){id, offerName, validFrom, validTo, locked, active, cancelled, updatedAt, deleted}
      }`

      return {
        query,
        variables: {
          ...checkpoint,
          batchSize: limit,
          filterDeleted: filterDeleted,
        },
      }
    },
    responseModifier: (
      documents: (Offer & { _deleted: boolean })[],
      _,
      requestCheckpoint = emptyCheckpoint
    ) => ({
      checkpoint:
        documents.length === 0
          ? requestCheckpoint
          : createCheckpoint(documents[documents.length - 1]),
      documents,
    }),
  },
  authentication: true,
  liveInterval: 5 * 60 * 1000, // 5 min
}
