import { createRxDatabase, CollectionsOfDatabase, addRxPlugin, RxStorage, RxDatabase } from 'rxdb'
import { syncEntities } from '@obeta/data/lib/entities'
import { EntityNames, EntityDescriptor } from '@obeta/models/lib/models/Db/index'
import { entityMeta } from '@obeta/data/lib/entities/entityMetaData'
import { Subject } from 'rxjs'
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql'
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration'
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents'
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import type { IRecommendations } from '@obeta/data/lib/hooks/useSearchRecommendations'
import type { SearchHistory } from '@obeta/data/lib/hooks/useSearchHistory'
import { bootstrapInitialMetaDataDocs } from '@obeta/utils/lib/epics-helpers'
import { getRxDbStorage } from './getRxDbStorage'
import { resync } from './resync'
import { replicateGraphQL } from 'rxdb/plugins/replication-graphql'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'

/**
 * make sure that plugins added only once
 */
let pluginsAdded = false

let db: RxDatabase

interface ReplicationAction {
  type: 'start' | 'stop'
  entities?: string[]
  baseUrl?: string
  token?: string
  errorHandler?: (err: Error) => void
}

type TReplicationState = RxGraphQLReplicationState<unknown, unknown>

export const replication$ = new Subject<ReplicationAction>()

replication$.subscribe(async (action) => {
  let entityList = syncEntities
  if (action.entities && action.entities?.length > 0) {
    entityList = action.entities.map((entity) =>
      syncEntities.find((ent) => ent.name === entity)
    ) as typeof syncEntities
  }

  if (action.type === 'stop') {
    for (const ent of entityList) {
      resync.cancelReplication(ent.name)
    }
  }

  if (action.type === 'start') {
    const client = await db.getLocal('client')
    const frontendClientId = client?.get('frontendClientId')

    for (const ent of entityList) {
      const replState = resync.getReplState(ent.name)

      if (replState && ent.authentication === true && action.token) {
        replState.setHeaders({
          Authorization: `bearer ${action.token}`,
          'x-frontend-client-id': frontendClientId,
        })
      } else if (ent.pullSync && action.baseUrl && !replState) {
        // without token, we won't create syncs, that need auth
        // this happens when the user signed in as guest
        if (!action.token && ent.authentication === true) {
          continue
        }

        if (!db[ent.name]) {
          continue
        }
        const nextReplState = replicateGraphQL({
          collection: db[ent.name],
          url: { http: action.baseUrl },
          deletedField: 'deleted', // the flag which indicates if a pulled document is deleted
          pull: ent.pullSync,
          live: true,
          retryTime: 60 * 1000,
          ...(ent.authentication === true &&
            action.token && {
              headers: {
                Authorization: `Bearer ${action.token}`,
                'x-frontend-client-id': frontendClientId,
              },
            }),
          // TODO: Check why typescript not happy in runResyncLoop with ObetaModels instead of any
          /* eslint-disable @typescript-eslint/no-explicit-any */
        }) as RxGraphQLReplicationState<any, unknown>

        resync.runResyncLoop(ent.name, nextReplState, ent.liveInterval || 30 * 1000)

        if (action.errorHandler) {
          nextReplState.error$.subscribe(action.errorHandler)
        }

        try {
          await db.waitForLeadership()
          await changeMetaData(db, ent.name, { isFetching: true })
          await nextReplState.awaitInitialReplication()
          await changeMetaData(db, ent.name, { isFetching: false })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Could not change metadata on leadership change')
          console.error(err)
        }
      }
    }
  }
})

export const bootstrapDatabase = async (
  multiInstance = true,
  ignoreDuplicate = false,
  entitiesToUse?: EntityNames[],
  forceNewInstance?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forcedStorageAdapter?: () => RxStorage<any, any>
) => {
  if (db) {
    if (forceNewInstance) {
      await db.remove()
      await db.destroy()
    } else {
      return db
    }
  }
  try {
    const storage = getRxDbStorage(forcedStorageAdapter)

    if (!pluginsAdded) {
      addRxPlugin(RxDBMigrationPlugin)
      addRxPlugin(RxDBLocalDocumentsPlugin)
      addRxPlugin(RxDBCleanupPlugin)
      addRxPlugin(RxDBUpdatePlugin)
      addRxPlugin(RxDBLeaderElectionPlugin)
      if (process.env.NODE_ENV === 'development') {
        const module = await import('rxdb/plugins/dev-mode')
        addRxPlugin(module.RxDBDevModePlugin)
      }
      pluginsAdded = true
    }

    db = await createRxDatabase<CollectionsOfDatabase>({
      name: 'obetav14', // <- name
      storage,
      eventReduce: true, // <- queryChangeDetection (optional, default: false)
      multiInstance, // <- multiInstance (optional, default: true)
      ignoreDuplicate,
      localDocuments: true,
      cleanupPolicy: {
        minimumDeletedTime: 1000 * 60 * 60 * 24 * 7, // week
        minimumCollectionAge: 1000 * 60, // 1 min
        runEach: 1000 * 60 * 5, // 5 minutes
        awaitReplicationsInSync: true,
        waitForLeadership: true,
      },
    })

    const userMetaDoc = await db.getLocal('usermeta')
    if (!userMetaDoc) {
      await db.insertLocal('usermeta', {})
    }

    const userDoc = await db.getLocal('user')
    if (!userDoc) {
      await db.insertLocal('user', {})
    }

    const userDoc2 = await db.getLocal('userv2')
    if (!userDoc2) {
      await db.insertLocal('userv2', {})
    }

    const searchRecommendations = await db.getLocal<IRecommendations>('recommendations')
    if (!searchRecommendations) {
      await db.insertLocal<IRecommendations>('recommendations', { data: [] })
    }

    const searchHistoryDoc = await db.getLocal<SearchHistory>('searchHistory')
    if (!searchHistoryDoc) {
      await db.insertLocal('searchHistory', {
        history: [],
      })
    }

    const searchSuggestions = await db.getLocal('searchSuggestions')
    if (!searchSuggestions) {
      await db.insertLocal('searchSuggestions', {
        suggestions: [],
      })
    }

    const pushToken = await db.getLocal('pushToken')
    if (!pushToken) {
      await db.insertLocal('pushToken', { token: null })
    }

    const openPositions = await db.getLocal('openpositions')
    if (!openPositions) {
      await db.insertLocal('openpositions', [])
    }

    const client = await db.getLocal('client')
    if (!client) {
      const frontendClientId = Math.random().toString(36).substring(2, 11)
      await db.insertLocal('client', { frontendClientId })
    }

    //create entitymeta collection first
    await db.addCollections({
      [entityMeta.name]: {
        schema: entityMeta.schema,
        migrationStrategies: entityMeta.migrationStrategies,
        localDocuments: true,
      },
    })

    let entitiesToManage: EntityDescriptor[] = syncEntities
    if (entitiesToUse) {
      entitiesToManage = syncEntities.filter(
        (ent: EntityDescriptor) => entitiesToUse.indexOf(ent.name) !== -1 // todo: why not to use entitiesToUse instead of filtering?
      )
    }

    const data: Record<
      string,
      Pick<EntityDescriptor, 'schema' | 'migrationStrategies' | 'localDocuments'>
    > = {}
    entitiesToManage.forEach((ent: EntityDescriptor) => {
      data[ent.name] = {
        schema: ent.schema,
        migrationStrategies: ent.migrationStrategies,
        localDocuments: true,
      }
    })
    await db.addCollections(data)

    const entityNames: EntityNames[] = []
    entitiesToManage.forEach((ent: EntityDescriptor) => {
      entityNames.push(ent.name)
    })

    if (db.isLeader()) {
      await bootstrapInitialMetaDataDocs(db, entityNames)
    } else {
      db.waitForLeadership().then(() => {
        return bootstrapInitialMetaDataDocs(db, entityNames)
      })
    }

    return db
  } catch (e) {
    console.error(e)
    throw new Error(e.message)
  }
}

export const getCollectionSync = (entityName: EntityNames): TReplicationState | undefined =>
  resync.getReplState(entityName)

export const syncDataWithDatabase = async (
  collectionName: EntityNames,
  requestData: Record<string, unknown>[],
  compareKey: string
) => {
  const collection = db[collectionName]
  const prevData: object[] = (await collection?.find().exec()) ?? []

  const toRemove: string[] = []

  prevData.forEach((prev) => {
    const exists = requestData.find((store) => store[compareKey] === prev[compareKey])
    if (!exists) toRemove.push(prev[compareKey])
  })

  await collection.bulkUpsert(requestData)

  if (toRemove.length > 0) await collection.bulkRemove(toRemove)
}

export const cancelReplication = resync.cancelReplications
