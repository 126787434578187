import { useFlags } from 'launchdarkly-react-client-sdk'

// Feature toggles with fallback variations
export const featureToggles = {
  SHOP_VERSION: '',
  UsePackageTrackingInfos: false,
  UseSapPromotionalProductsVisible: false,
  UseCreatedOrderPromotionForm: false,
  UseRequestOfferCancellation: false,
  UseShowOrdersOnStartPage: false,
  UseShowModifiedSumsForOffer: false,
  UseOrdersFeedback: false,
  NOTIFICATION_UNDO_BUTTON: false,
  UsePaymentProvider: false,
  ShowDialogForDiscontinuedItemWithLimitedStock: false,
  UseImgProxy: false,
  UseSearchFeedback: false,
  UseNewOrderListPageInApp: false,
  UseNewSupplierOverview: false,
  UseOldOrders: true,
  ShowOxomiCatalogs: false,
  useTecselectIntegration: false,
  usePaypal: false,
  UseNewUserManagement: false,
  UseAvailableDefault: false,
  UsePrintBauDoc: false,
  UseIndividualDataSheetToggle: false,
  UseNewWarehouses: false,
  UseOrderItemsInArrears: false,
  UseSignUp: false,
  UseFastProductEntryFeedback: false,
  UseHeaderAddressElement: false,
  UseCreditLimitPreAlert: false,
  useAddToCartProcessIndicator: false,
  UseAlgoliaDrivenPromotionBanner: false,
} as const

export type Feature = keyof typeof featureToggles

export const useFeatureToggle = (feature: Feature) => {
  const flags = useFlags()

  return flags[feature] ?? false
}
