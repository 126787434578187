import { useCallback, useState } from 'react'
import { useEntities } from '@obeta/data/lib/hooks'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'

export const useAddressManagementModal = () => {
  const [showAddressManagementModal, setShowAddressManagementModal] = useState(false)
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')
  const { user } = useUserDataV2()
  const canWriteAddress = user?.permissions?.Cart_canWriteAddress
  const history = useHistory()

  // prevent dialog in shopping cart
  const locationLocked = [ShopRoutes.ShoppingCartCheckout, ShopRoutes.ShoppingCartDetails].some(
    (location) => history.location.pathname.startsWith(location)
  )

  const openModal = useCallback(() => setShowAddressManagementModal(true), [])
  const closeModal = useCallback(() => setShowAddressManagementModal(false), [])
  return {
    openModal,
    closeModal,
    showAddressManagementModal,
    userAddresses,
    canWriteAddress: canWriteAddress && !locationLocked,
  }
}
